// Services
import { getPPIInfoService } from '../services/financing-ppi.service';
// Types
import { FinancingPPIInfoType } from '../types/financing-ppi-info.type';
// Utils
import { isValidPPIInfoResponse } from './utils/financing-ppi.resolver.utils';

const getPPIInfoResolver = async (): Promise<[FinancingPPIInfoType] | [null, boolean]> => {
  const [response] = await getPPIInfoService();

  return isValidPPIInfoResponse(response) ? [response] : [null, true];
};

export { getPPIInfoResolver };
