// Vendors
import styled from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const AmortizationModalComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: grid;
  grid-template-columns: auto minmax(120px, auto) auto auto minmax(83px, auto) auto auto;
  height: ${({ theme }) => theme.spaces.spacingL};
  padding: ${({ theme }) => theme.spaces.spacingXxs} ${({ theme }) => theme.spaces.spacingXs};
`;

const AmortizationModalStyled = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const InstalmentsStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: left;
  margin-left: ${({ theme }) =>
    `calc(${theme.spaces.spacingXxxxxxl} + ${theme.spaces.spacingS} + ${theme.spaces.spacingXxxs})`};
`;

const InstallmentsStyled = styled.div`
  border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingS};
`;

const ParagraphCenteredComponentStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spaces.spacingS};
`;

const ParagraphLeftComponentStyled = styled(ParagraphComponent)`
  display: flex;
  margin-right: ${({ theme }) => theme.spaces.spacingXxl};
  margin-left: ${({ theme }) => `calc(${theme.spaces.spacingS} + ${theme.spaces.spacingXxxxs})`};
`;

const ParagraphComponentStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: right;
  margin-right: ${({ theme }) => theme.spaces.spacingXxl};
`;

const ParagraphTitleCenteredStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spaces.spacingXxl};
`;

const ParagraphInterestAmountComponentStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: right;
  margin-right: ${({ theme }) => theme.spaces.spacingXxs};
`;

const ParagraphCapitalAmountComponentStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: right;
  margin-right: ${({ theme }) => theme.spaces.spacingXxxs};
`;

const ParagraphAmountComponentStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: right;
`;

export {
  AmortizationModalComponentStyled,
  AmortizationModalStyled,
  InstallmentsStyled,
  InstalmentsStyled,
  ParagraphAmountComponentStyled,
  ParagraphCapitalAmountComponentStyled,
  ParagraphCenteredComponentStyled,
  ParagraphComponentStyled,
  ParagraphInterestAmountComponentStyled,
  ParagraphLeftComponentStyled,
  ParagraphTitleCenteredStyled,
};
