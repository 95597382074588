// Types
import {
  FinancingPPIContractingStep1HiringOptionsBuilderHandlersType,
  FinancingPPIContractingStep1HiringOptionsMethodHandlersType,
  FinancingPPIContractingStep1HiringOptionsMethodsHandlersType,
} from './types/financing-ppi-contracting-step1-hiring-options.handlers.type';

const choosePPIAgreementCheckClickHandler = async ({
  handleChoosePPIAgreementCheckClickTracking,
  option,
  setOption,
  setValidated,
}: FinancingPPIContractingStep1HiringOptionsMethodHandlersType): Promise<void> => {
  handleChoosePPIAgreementCheckClickTracking(option);
  setOption(option);
  setValidated(false);
};

const FinancingPPIContractingStep1HiringOptionsHandlers = (
  props: FinancingPPIContractingStep1HiringOptionsBuilderHandlersType
): FinancingPPIContractingStep1HiringOptionsMethodsHandlersType => ({
  handleChoosePPIAgreementCheckClick: (option: boolean) =>
    choosePPIAgreementCheckClickHandler({ ...props, option }),
});

export { FinancingPPIContractingStep1HiringOptionsHandlers };
