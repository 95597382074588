// Vendors
import styled from 'styled-components';
import { LayoutColumnFlexComponentStyled } from '@/styles/app.styled';
// Utilities

const FinancingPPIContractingStep1FeaturesSectionComponentStyled = styled(
  LayoutColumnFlexComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
FinancingPPIContractingStep1FeaturesSectionComponentStyled.displayName =
  'FinancingPPIContractingStep1FeaturesSectionComponentStyled';

const FinancingPPIContractingStep1FeaturesSectionListComponentStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  margin: 0;
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;
FinancingPPIContractingStep1FeaturesSectionListComponentStyled.displayName =
  'FinancingPPIContractingStep1FeaturesSectionListComponentStyled';

const FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled = styled.li`
  width: 100%;
`;
FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled.displayName =
  'FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled';

export {
  FinancingPPIContractingStep1FeaturesSectionComponentStyled,
  FinancingPPIContractingStep1FeaturesSectionListComponentStyled,
  FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled,
};
