const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step1.whatYouGet.';

const TITLE = `${DOMAIN}title`,
  FEATURE_01 = `${DOMAIN}description01`,
  FEATURE_02 = `${DOMAIN}description02`,
  FEATURE_03 = `${DOMAIN}description03`,
  FEATURE_04 = `${DOMAIN}description04`,
  FEATURE_05 = `${DOMAIN}description05`;

export { TITLE, FEATURE_01, FEATURE_02, FEATURE_03, FEATURE_04, FEATURE_05 };
