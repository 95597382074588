// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { FinancingPPIContractingStep1CoverageDescriptionLinkComponent } from '../description-link/financing-ppi-contracting-step1-coverage-description-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_TERMINATE_PROPS } from './constants/financing-ppi-contracting-step1-coverage-terminate.constants';

const FinancingPPIContractingStep1CoverageTerminateComponent = (): React.ReactElement | null => (
  <FinancingPPIContractingStep1CoverageDescriptionLinkComponent
    {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_TERMINATE_PROPS}
  />
);

export { FinancingPPIContractingStep1CoverageTerminateComponent };
