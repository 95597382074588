// Enumerations
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Translations
import { ERROR_DOWNLOAD } from '../translations/financing-ppi-landing.handlers.translations';

const ERROR_TOAST_CONFIGURATION = {
  type: ToastTypesEnumeration.ERROR,
  description: ERROR_DOWNLOAD,
};

export { ERROR_TOAST_CONFIGURATION };
