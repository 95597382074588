// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Types
import {
  FinancingPPIContractingStep1BuilderHandlersType,
  FinancingPPIContractingStep1MethodsHandlersType,
} from './types/financing-ppi-contracting-step1.handlers.type';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';
import { noneAgreementOptionsIsSelected } from './utils/financing-ppi-contracting-step1.handlers.utils';

const transportUserNextContractingStep = ({
  navigate,
  option,
  setCurrentStep,
}: Omit<FinancingPPIContractingStep1BuilderHandlersType, 'setValidated'>): void => {
  const url = generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]);

  option ? setCurrentStep(WizardStepsEnumeration.TWO) : navigate(generateAbsoluteRoute(url));
};
const validateAgreementOptionButtonClickHandler = ({
  navigate,
  option,
  setCurrentStep,
  setValidated,
}: FinancingPPIContractingStep1BuilderHandlersType): void => {
  noneAgreementOptionsIsSelected(option)
    ? setValidated(true)
    : transportUserNextContractingStep({ navigate, option, setCurrentStep });
};

const FinancingPPIContractingStep1Handlers = (
  props: FinancingPPIContractingStep1BuilderHandlersType
): FinancingPPIContractingStep1MethodsHandlersType => ({
  handleValidateAgreementOptionButtonClick: () => validateAgreementOptionButtonClickHandler(props),
});

export { FinancingPPIContractingStep1Handlers };
