// Vendors
import { useState } from 'react';
// Handlers
import { FinancingLandingRowDetailsOrdersHandler } from '../handlers/financing-landing-row-details-order.handler';
import { FinancingLandingRowDetailsOrdersTrackingHandlers } from '../handlers/financing-landing-row-detail-order.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingLandingRowDetailsOrdersHooksReturnType } from './types/financing-landing-row-details-orders.hook.type';

const FinancingLandingRowDetailsOrdersHooks =
  (): FinancingLandingRowDetailsOrdersHooksReturnType => {
    const [open, setOpen] = useState(false);

    return {
      ...FinancingLandingRowDetailsOrdersHandler({
        setOpen,
        open,
        ...AppTrackingHook(FinancingLandingRowDetailsOrdersTrackingHandlers),
      }),
      open,
    };
  };

export { FinancingLandingRowDetailsOrdersHooks };
