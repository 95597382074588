// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPaymentMethodContextConsumerHook } from '../contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodHandlers from '../handlers/financing-payment-method.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import usePaymentMethodHook from './payment-method.hook';
// Types
import { FinancingPaymentMethodReturnHookType } from './types/financing-payment-method-return.hook';

const FinancingPaymentMethodHook = (): FinancingPaymentMethodReturnHookType => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { formatMessage } = useCbIntl(),
    { handleSelectCurrentMethod } = usePaymentMethodHook();

  const { error, setError, setRevolvingOptions } = FinancingPaymentMethodContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { creditDetails } = currentCard || {},
    { billingCyclePeriodicity } = creditDetails || {};

  const { handleLoadRevolvingOptions } = FinancingPaymentMethodHandlers({
    handleSelectCurrentMethod,
    setError,
    setFetching,
    setRevolvingOptions,
  });

  useEffect(() => {
    handleLoadRevolvingOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    billingCyclePeriodicity,
    error,
    fetching,
    formatMessage,
  };
};

export default FinancingPaymentMethodHook;
