// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { FinancingPPIContractingStep1CoverageDescriptionLinkComponent } from '../description-link/financing-ppi-contracting-step1-coverage-description-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_CIRCUMSTANCES_PROPS } from 'containers/financing/views/ppi/components/contracting/components/step1/components/coverage/components/circumstances/constants/financing-ppi-contracting-step1-coverage-circumstances.constants';

const FinancingPpiContractingStep1CoverageCircumstancesComponent =
  (): React.ReactElement | null => (
    <FinancingPPIContractingStep1CoverageDescriptionLinkComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_CIRCUMSTANCES_PROPS}
    />
  );

export { FinancingPpiContractingStep1CoverageCircumstancesComponent };
