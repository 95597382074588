const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step1.coverage.coverageLimitations.';

const ITEM_TITLE_01 = `${DOMAIN}deathIllness.title`,
  ITEM_01_SUBITEM_01 = `${DOMAIN}deathIllness.feature01`,
  ITEM_01_SUBITEM_02 = `${DOMAIN}deathIllness.feature02`,
  ITEM_01_SUBITEM_03 = `${DOMAIN}deathIllness.feature03`,
  ITEM_TITLE_02 = `${DOMAIN}temporaryDisability.title`,
  ITEM_02_SUBITEM_01 = `${DOMAIN}temporaryDisability.feature01`,
  ITEM_02_SUBITEM_02 = `${DOMAIN}temporaryDisability.feature02`,
  ITEM_TITLE_03 = `${DOMAIN}involutaryUnemployee.title`,
  ITEM_03_SUBITEM_01 = `${DOMAIN}involutaryUnemployee.feature01`,
  ITEM_03_SUBITEM_02 = `${DOMAIN}involutaryUnemployee.feature02`;

export {
  ITEM_TITLE_01,
  ITEM_01_SUBITEM_01,
  ITEM_01_SUBITEM_02,
  ITEM_01_SUBITEM_03,
  ITEM_TITLE_02,
  ITEM_02_SUBITEM_01,
  ITEM_02_SUBITEM_02,
  ITEM_TITLE_03,
  ITEM_03_SUBITEM_01,
  ITEM_03_SUBITEM_02,
};
