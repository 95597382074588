// Vendors
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Contexts
import { FinancingPPIContextConsumerHOC } from './contexts/financing-ppi.context';
// Constants
import {
  FINANCING_PPI_ROUTES,
  FINANCING_ANY_NAVIGATE_ROUTE_PROPS,
  FINANCING_ANY_ROUTE_PROPS,
} from './constants/financing-ppi.constants';
// Hooks
import { useFinancingPPIHook } from './hooks/financing-ppi.hook';

const FinancingPPIBaseView = (): React.ReactElement => {
  const { fetching } = useFinancingPPIHook();

  return (
    <FetchErrorComponent {...{ fetching }}>
      <Routes>
        {FINANCING_PPI_ROUTES.map(
          ({ element: RouteComponent, props }, index: number): ReactNode => (
            <Route {...props} element={<RouteComponent />} key={index} />
          )
        )}
        <Route
          {...FINANCING_ANY_ROUTE_PROPS}
          element={<Navigate {...FINANCING_ANY_NAVIGATE_ROUTE_PROPS} />}
        />
      </Routes>
    </FetchErrorComponent>
  );
};

const FinancingPPIView = FinancingPPIContextConsumerHOC(FinancingPPIBaseView);

export { FinancingPPIView };
