// Translations
import {
  FEATURE_01,
  FEATURE_02,
  FEATURE_03,
  FEATURE_04,
  FEATURE_05,
  TITLE,
} from '../translations/financing-ppi-contracting-step1-what.translations';

const FINANCING_PPI_CONTRACTING_STEP_1_WHAT_PROPS = {
  features: [FEATURE_01, FEATURE_02, FEATURE_03, FEATURE_04, FEATURE_05],
  title: TITLE,
};

export { FINANCING_PPI_CONTRACTING_STEP_1_WHAT_PROPS };
