// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FINANCING_PPI_LANDING_SERVICE_CONFIGURATION } from './constants/financing-ppi-landing.service.constants';

const fetchTermsAndPolicyDocumentService = async (): Promise<
  [Blob | Record<string, never>, Response]
> => apiUtilsCb.get(FINANCING_PPI_LANDING_SERVICE_CONFIGURATION);

export { fetchTermsAndPolicyDocumentService };
