// Enumerations
import { PpiStatusEnumeration } from 'containers/financing/views/ppi/enumerations/ppi-status.enumeration';
// Translations
import { STILL_INACTIVE } from './translations/financing-ppi-landing.utils.translations';
// Types
import { FinancingPPIReturnHookType } from '../hooks/types/financing-ppi-landing.hook.type';

const getCardHelperMessage = ({
  formatMessage,
  status,
}: Pick<FinancingPPIReturnHookType, 'formatMessage' | 'status'>): string =>
  status === PpiStatusEnumeration.TEMPORAL ? formatMessage({ id: STILL_INACTIVE }) : '';

const customerHasCurrentlyPPIContracted = (status: FinancingPPIReturnHookType['status']): boolean =>
  [
    PpiStatusEnumeration.ACTIVE,
    PpiStatusEnumeration.COOLING_OFF,
    PpiStatusEnumeration.CREATED,
  ].includes(status as PpiStatusEnumeration);

export { customerHasCurrentlyPPIContracted, getCardHelperMessage };
