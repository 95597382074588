// Vendors
import styled from 'styled-components';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const AmortizationTableComponentStyled = styled.div`
  width: 100%;
`;

const CustomModal = styled(ModalComponent)`
  max-width: ${convertPxsToRems({ pixels: 1200, base: 14 })};
`;

export { AmortizationTableComponentStyled, CustomModal };
