// Vendors
import styled from 'styled-components';
// Utilities
import { LayoutColumnFlexComponentStyled } from '@/styles/app.styled';

const FinancingPPIContractingStep1HiringComponentStyled = styled(LayoutColumnFlexComponentStyled)`
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
FinancingPPIContractingStep1HiringComponentStyled.displayName =
  'FinancingPPIContractingStep1HiringComponentStyled';

export { FinancingPPIContractingStep1HiringComponentStyled };
