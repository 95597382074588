// Types
import { LoginLinksBuilderReturnHandlersType } from 'containers/login/components/links/handlers/types/login-links-builder-return.handlers.type';
import { LoginLinksBuilderHandlersType } from 'containers/login/components/links/handlers/types/login-links-builder.handlers.type';
import { LoginLinksMethodBuilderHandlersType } from 'containers/login/components/links/handlers/types/login-links-method-builder.handlers.type';

const openLoginEmailLinkClickHandler = ({
  handleOpenLoginEmailLinkClickTracking,
  href,
  isExternal,
  navigate,
}: LoginLinksMethodBuilderHandlersType): any => {
  handleOpenLoginEmailLinkClickTracking(href);
  isExternal ? window.location.replace(href) : navigate(href);
};

const LoginLinksHandlers = (
  props: LoginLinksBuilderHandlersType
): LoginLinksBuilderReturnHandlersType => ({
  handleOpenLoginEmailLinkClick: ({ href, isExternal }) =>
    openLoginEmailLinkClickHandler({ ...props, href, isExternal }),
});

export default LoginLinksHandlers;
