// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingStep1FeaturesSectionComponent } from '../features-section/financing-ppi-contracting-step1-features-section.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_WHAT_PROPS } from './constants/financing-ppi-contracting-step1-what.constants';

const FinancingPPIContractingStep1WhatComponent = (): React.ReactElement => (
  <FinancingPPIContractingStep1FeaturesSectionComponent
    {...FINANCING_PPI_CONTRACTING_STEP_1_WHAT_PROPS}
  />
);

export { FinancingPPIContractingStep1WhatComponent };
