// Services
import { fetchTermsAndPolicyDocumentService } from '../services/financing-ppi-landing.service';
// Utils
import { createObjectUrlFromBlob, fileIsBlobType } from '@openbank/cf-ui-framework';

const fetchTermsAndPolicyDocumentResolver = async (): Promise<[string] | [null, boolean]> => {
  const [response] = await fetchTermsAndPolicyDocumentService();

  return fileIsBlobType(response) ? [createObjectUrlFromBlob(response)] : [null, true];
};

export { fetchTermsAndPolicyDocumentResolver };
