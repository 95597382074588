// Resolvers
import { getPPIInfoResolver } from '../resolvers/financing-ppi.resolver';
// Types
import {
  FinancingPPIHandlersBuilderReturnType,
  FinancingPPIHandlersBuilderType,
} from './types/financing-ppi.handlers.type';

const fetchPPIInformationEventHandler = async ({
  setError,
  setFetching,
  setFetchPPI,
  setPPIInfo,
}: FinancingPPIHandlersBuilderType): Promise<void> => {
  setFetching(true);

  const [response, error] = await getPPIInfoResolver();

  response ? setPPIInfo(response) : setError(true);
  setFetching(false);
  setFetchPPI(false);
};

const FinancingPPIHandlers = (
  props: FinancingPPIHandlersBuilderType
): FinancingPPIHandlersBuilderReturnType => ({
  handleFetchPPIInformationEvent: () => fetchPPIInformationEventHandler(props),
});

export { FinancingPPIHandlers };
