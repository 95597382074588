// Translations
import {
  DESCRIPTION,
  TITLE,
} from '../translations/financing-ppi-contracting-step1-coverage-circumstances.translations';

const FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_CIRCUMSTANCES_PROPS = {
  description: DESCRIPTION,
  title: TITLE,
};

export { FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_CIRCUMSTANCES_PROPS };
