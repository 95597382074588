// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { ConsentPPIStep2IdsEnumeration } from '../enumerations/consent-ppi-step2-ids.enumeration';
// Translations
import {
  CNSPI_CONSENT,
  LEGAL_TEXT,
  MESSAGE,
  TERMS_ACCEPTANCE_CONSENT,
} from '../translations/financing-ppi-contracting-step2.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-contracting-step2-description',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CONTRACT_PROPS = {
    consentId: ConsentPPIStep2IdsEnumeration.CONTRACT,
    label: TERMS_ACCEPTANCE_CONSENT,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CNSPI_PROPS = {
    consentId: ConsentPPIStep2IdsEnumeration.CNSPI,
    label: CNSPI_CONSENT,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS = {
    label: MESSAGE,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS = {
    description: LEGAL_TEXT,
    size: paragraphConstants.SIZES.XS,
    testId: 'legal',
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CONTRACT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CNSPI_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS,
};
