// Types
import { FinancingLandingTableRowDetailsUtilsType } from './types/financing-landing-table-row-details.utils.types';

export const FinancingLandingTableRowDetailsUtils = (): FinancingLandingTableRowDetailsUtilsType => {
  const formatCurrency = (value, locale = 'de-DE', currency = 'EUR') => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
  };

  const formatPercentage = (value, locale = 'de-DE') => {
    return new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 2 }).format(
      value / 100
    );
  };
  return { formatCurrency, formatPercentage };
};
