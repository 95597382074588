// Vendors
import React from 'react';
// Components
import { CommercialCardComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getPPILandingButtonConfiguration } from './configurations/financing-ppi-landing-button.configuration';
import { getPPILandingCardBaseConfiguration } from './configurations/financing-ppi-landing.configuration';
import { getPPILandingLinkContractConfiguration } from './configurations/financing-ppi-landing-link.configuration';
import { getPPILandingStatusConfiguration } from './configurations/financing-ppi-landing-status.configuration';
import { getPPILandingTagConfiguration } from './configurations/financing-ppi-landing-tag.configuration';
// Hooks
import { useFinancingPPILandingHook } from './hooks/financing-ppi-landing.hook';
// Utilities
import { getCardHelperMessage } from './utils/financing-ppi-landing.utils';

const FinancingPPILandingComponent = (): React.ReactElement => {
  const {
    customerContractingEnabled,
    formatMessage,
    handleCancelPPIContractButtonClick,
    handleContractPPIButtonClick,
    handleViewTermsPolicyLinkClick: onClick,
    ppiPercentage,
    status,
    ...props
  } = useFinancingPPILandingHook();

  return (
    <CommercialCardComponent
      {...getPPILandingCardBaseConfiguration({ formatMessage, ppiPercentage })}
      buttonConfiguration={getPPILandingButtonConfiguration({
        customerContractingEnabled,
        formatMessage,
        handleCancelPPIContractButtonClick,
        handleContractPPIButtonClick,
        status,
      })}
      statusConfiguration={{
        ...getPPILandingLinkContractConfiguration({ formatMessage, onClick, status }),
        helperText: getCardHelperMessage({ formatMessage, status }),
        statusInfo: getPPILandingStatusConfiguration({ ...props, formatMessage, status }),
        tagConfiguration: getPPILandingTagConfiguration({ formatMessage, status }),
      }}
    />
  );
};

export { FinancingPPILandingComponent };
