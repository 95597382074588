// Contexts
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
import { AppContextConsumerHook } from 'contexts/app.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Handlers
import FinancingPaymentMethodFirstStepActionsHandlers from '../handlers/financing-payment-method-first-step-actions.handlers';
// Types
import { FinancingPaymentMethodFirstStepActionsHookReturnType } from './types/financing-payment-method-first-step-actions.hook.type';

const FinancingPaymentMethodFirstStepActionsHook =
  (): FinancingPaymentMethodFirstStepActionsHookReturnType => {
    const { currentCard } = AppContextConsumerHook(),
      { creditDetails } = currentCard || {},
      { accountBalancePercentage, fixedPaymentAmount, paymentMethodCode } = creditDetails || {},
      { fee, paymentMethod, revolvingMethod, setError, setPaymentMethodParams } =
        FinancingPaymentMethodContextConsumerHook(),
      { setCurrentStep } = useWizardContextConsumerHook();

    return {
      ...FinancingPaymentMethodFirstStepActionsHandlers({
        fee,
        paymentMethod,
        revolvingMethod,
        setCurrentStep,
        setError,
        setPaymentMethodParams,
      }),
      accountBalancePercentage,
      fee,
      fixedPaymentAmount,
      paymentMethod,
      paymentMethodCode,
      revolvingMethod,
    };
  };

export default FinancingPaymentMethodFirstStepActionsHook;
