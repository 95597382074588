// Constants
import {
  FINANCING_PPI_COMMON_ROUTES,
  FINANCING_ANY_ROUTE_PROPS,
  FINANCING_ANY_NAVIGATE_ROUTE_PROPS,
} from './financing-ppi.common.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Views
import { FinancingPPIContractingComponent } from '../components/contracting/financing-ppi-contracting.component';

const FINANCING_PPI_ROUTES: RouteType[] = [
  ...FINANCING_PPI_COMMON_ROUTES,
  {
    element: FinancingPPIContractingComponent,
    props: { path: RoutesEnumeration.PPI_CONTRACTING },
  },
];

export { FINANCING_PPI_ROUTES, FINANCING_ANY_NAVIGATE_ROUTE_PROPS, FINANCING_ANY_ROUTE_PROPS };
