// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ExpandableRowComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep1CoverageLinkComponent } from '../link/financing-ppi-contracting-step1-coverage-link.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_EXPANDABLE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_PROPS,
} from './constants/financing-ppi-contracting-step1-coverage-description-link.constants';
// Types
import { FinancingPPIContractingStep1CoverageDescriptionLinkType } from './types/financing-ppi-contracting-step1-coverage-description-link.type';

const FinancingPPIContractingStep1CoverageDescriptionLinkComponent = ({
  description,
  title,
}: FinancingPPIContractingStep1CoverageDescriptionLinkType): React.ReactElement | null => (
  <ExpandableRowComponent
    {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_EXPANDABLE_PROPS}
    title={<FormattedMessageComponent id={title} />}
  >
    <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_PROPS}>
      <FormattedMessageComponent
        id={description}
        values={{
          link: (chunks: string | React.ReactNode) => (
            <FinancingPPIContractingStep1CoverageLinkComponent {...{ chunks }} />
          ),
        }}
      />
    </ParagraphComponent>
  </ExpandableRowComponent>
);

export { FinancingPPIContractingStep1CoverageDescriptionLinkComponent };
