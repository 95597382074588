const FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_NO_PROPS = {
    testing: {
      component: 'financing-ppi-contracting-step1-hiring-option-no-content',
      dot: 'financing-ppi-contracting-step1-hiring-option-no-content',
      title: 'financing-ppi-contracting-step1-hiring-option-no-title',
    },
  },
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_YES_PROPS = {
    testing: {
      component: 'financing-ppi-contracting-step1-hiring-option-yes-content',
      dot: 'financing-ppi-contracting-step1-hiring-option-yes-content',
      title: 'financing-ppi-contracting-step1-hiring-option-yes-title',
    },
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_NO_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_YES_PROPS,
};
