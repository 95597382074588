// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-annual-percentage.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';
// Translations
import translations from './translations/financing-landing-row-details-annual-percentage.translations';
// Types
import { FinancingLandingRowDetailsAnnualPercentageComponentType } from './types/financing-landing-row-details-annual-percentage.component.type';

const FinancingLandingRowDetailsAnnualPercentageComponent = ({
  annualPercentageRate,
}: FinancingLandingRowDetailsAnnualPercentageComponentType): React.ReactElement => {
  const { formatPercentage } = FinancingLandingTableRowDetailsUtils();
  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_APR_PROPS}
      title={<FormattedMessageComponent id={translations.ANNUAL_PERCENTAGE} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatPercentage(annualPercentageRate)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsAnnualPercentageComponent;
