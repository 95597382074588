// Types
import {
  FinancingPpiContractingStep1CoverageItemUtilsType,
  FinancingPpiContractingStep1CoverageUtilsType,
} from './types/financing-ppi-contracting-step1-coverage.utils.type';
// Utilities
import { translateItemsList } from 'utils/translations.utils';

const mapAndTranslateSectionsListItems = ({
  formatMessage,
  items,
}: FinancingPpiContractingStep1CoverageUtilsType): FinancingPpiContractingStep1CoverageItemUtilsType[] =>
  items.map(({ label: id, subItems }: FinancingPpiContractingStep1CoverageItemUtilsType) => ({
    label: formatMessage({ id }),
    subItems: translateItemsList({ formatMessage, list: subItems }),
  }));

export { mapAndTranslateSectionsListItems };
