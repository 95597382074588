// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, TitleComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep1OptionsComponent } from './components/options/financing-ppi-contracting-step1-hiring-options.component';
import { FinancingPPIContractingStep1HiringMessageComponent } from './components/message/financing-ppi-contracting-step1-hiring-message.component';
import { FinancingPPIContractingStep1HiringLegalComponent } from './components/legal/financing-ppi-contracting-step1-hiring-legal.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_TITLE_PROPS,
} from './constants/financing-ppi-contracting-step1-hiring.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { FinancingPPIContractingStep1HiringComponentStyled } from './financing-ppi-contracting-step1-hiring.component.styled';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from './translations/financing-ppi-contracting-step1-hiring.translations';

const FinancingPPIContractingStep1HiringComponent = (): React.ReactElement => (
  <LayoutColumnFlexComponentStyled>
    <FinancingPPIContractingStep1HiringComponentStyled>
      <TitleComponent {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </TitleComponent>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <FinancingPPIContractingStep1OptionsComponent />
      <FinancingPPIContractingStep1HiringMessageComponent />
    </FinancingPPIContractingStep1HiringComponentStyled>
    <FinancingPPIContractingStep1HiringLegalComponent />
  </LayoutColumnFlexComponentStyled>
);

export { FinancingPPIContractingStep1HiringComponent };
