// Constants
import { DEFAULT_PAYMENT_METHOD_SUMMARY } from './constants/financing-payment-method-second-step-summary.hook.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodSecondStepSummaryHandlers from '../handlers/financing-payment-method-second-step-summary.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingPaymentMethodSecondStepSummaryReturnHookType } from './types/financing-payment-method-second-step-summary-return.hook.type';
import { useEffect, useState } from 'react';
import { PaymentMethodSummaryType } from '../types/payment-method-summary.type';

const FinancingPaymentMethodSecondStepSummaryHook =
  (): FinancingPaymentMethodSecondStepSummaryReturnHookType => {
    const [paymentMethodSummary, setPaymentMethodSummary] = useState<PaymentMethodSummaryType>(
      DEFAULT_PAYMENT_METHOD_SUMMARY
    );

    const { formatMessage, formatNumber } = useCbIntl();

    const { fee, paymentMethod, revolvingMethod, setError } =
      FinancingPaymentMethodContextConsumerHook();

    const { currentCard } = AppContextConsumerHook(),
      { creditDetails } = currentCard || {},
      { accountBalancePercentage, fixedPaymentAmount, paymentMethodCode } = creditDetails || {};

    const { handlePaymentMethodSummaryRender } = FinancingPaymentMethodSecondStepSummaryHandlers({
      accountBalancePercentage,
      fee,
      fixedPaymentAmount,
      formatMessage,
      formatNumber,
      paymentMethod,
      paymentMethodCode,
      revolvingMethod,
      setError,
      setPaymentMethodSummary,
    });

    useEffect(() => {
      handlePaymentMethodSummaryRender();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      formatMessage,
      newPaymentMethodLabel: paymentMethodSummary.newPaymentMethodLabel,
      paymentMethodLabel: paymentMethodSummary.paymentMethodLabel,
    };
  };

export default FinancingPaymentMethodSecondStepSummaryHook;
