// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingStep1FeaturesSectionComponent } from '../features-section/financing-ppi-contracting-step1-features-section.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_OVERVIEW_PROPS } from './constants/financing-ppi-contracting-step1-overview.constants';

const FinancingPPIContractingStep1OverviewComponent = (): React.ReactElement => (
  <FinancingPPIContractingStep1FeaturesSectionComponent
    {...FINANCING_PPI_CONTRACTING_STEP_1_OVERVIEW_PROPS}
  />
);

export { FinancingPPIContractingStep1OverviewComponent };
