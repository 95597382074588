// @Vendors
import { titleConstants } from '@openbank/cb-ui-commons';
// Constants
import {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_INSURANCES,
} from '../translations/financing-ppi-cancellation-landing-feedback.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';

const FINANCING_PPI_CANCELLATION_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-cancellation-landing-description',
  },
  FINANCING_PPI_CANCELLATION_TITLE_PROPS = {
    tag: titleConstants.TAGS.H4,
    testId: 'financing-ppi-cancellation-landing-title',
  },
  FINANCING_PPI_CANCELLATION_FEEDBACK_PROPS = {
    errorConfiguration: {
      description: FEEDBACK_ERROR_DESCRIPTION,
      title: FEEDBACK_ERROR_TITLE,
      linkLabelId: GO_TO_INSURANCES,
      exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]),
    },
    successConfiguration: {
      description: FEEDBACK_SUCCESS_DESCRIPTION,
      linkLabel: GO_TO_INSURANCES,
      title: FEEDBACK_SUCCESS_TITLE,
      exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI]),
    },
  };

export {
  FINANCING_PPI_CANCELLATION_DESCRIPTION_PROPS,
  FINANCING_PPI_CANCELLATION_TITLE_PROPS,
  FINANCING_PPI_CANCELLATION_FEEDBACK_PROPS,
};
