// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_TERMS_LINK_PROPS } from './constants/financing-ppi-contracting-step1-terms-link.constants';
// Hooks
import { useFinancingPPIContractingStep1FeaturesSectionHook } from './hooks/financing-ppi-contracting-step1-terms-link.hook';
// Types
import { FinancingPPIContractingStep1TermsLinkType } from './types/financing-ppi-contracting-step1-terms-link.type';

const FinancingPPIContractingStep1TermsLinkComponent = ({
  label,
}: FinancingPPIContractingStep1TermsLinkType): React.ReactElement => {
  const { handleOpenFinancingPPIContractingConditionsFileLinkClick } =
    useFinancingPPIContractingStep1FeaturesSectionHook();

  return (
    <LinkComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_TERMS_LINK_PROPS}
      onClick={handleOpenFinancingPPIContractingConditionsFileLinkClick}
    >
      {label}
    </LinkComponent>
  );
};

export { FinancingPPIContractingStep1TermsLinkComponent };
