// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingStep1HiringLegalTrackingHandlersType } from 'containers/financing/views/ppi/components/contracting/components/step1/components/hiring/components/legal/handlers/types/financing-ppi-contracting-step1-hiring-legal.tracking.handlers.type';

const openGermanBranchWebsiteLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'www.dgsfp.mineco.es',
  },
  type: TrackingEventsEnum.LINK,
});

const FinancingPPIContractingStep1HiringLegalTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingStep1HiringLegalTrackingHandlersType => ({
  handleOpenGermanBranchWebsiteLinkClickTracking: () =>
    track(openGermanBranchWebsiteLinkClickTrackingHandler()),
});

export { FinancingPPIContractingStep1HiringLegalTrackingHandlers };
