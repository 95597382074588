// Translations
import {
  FEATURE_01,
  FEATURE_02,
  FEATURE_03,
  FEATURE_04,
  FEATURE_05,
  FEATURE_06,
  TITLE,
} from '../translations/financing-ppi-contracting-step1-overview.translations';

const FINANCING_PPI_CONTRACTING_STEP_1_OVERVIEW_PROPS = {
  features: [FEATURE_01, FEATURE_02, FEATURE_03, FEATURE_04, FEATURE_05, FEATURE_06],
  title: TITLE,
};

export { FINANCING_PPI_CONTRACTING_STEP_1_OVERVIEW_PROPS };
