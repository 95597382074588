// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-current-amount.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Translations
import translations from './translations/financing-landing-row-details-current-amount.translations';
// Types
import { FinancingLandingRowDetailsCurrentAmountComponentType } from './types/financing-landing-row-details-current-amount.component.type';
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';

const FinancingLandingRowDetailsCurrentAmountComponent = ({
  currentAmount,
  originalAmount,
  status,
}: FinancingLandingRowDetailsCurrentAmountComponentType): React.ReactElement | null => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();
  const isDisplayable =
    status === EppElementStatusEnumeration.PARTIAL_RETURNED ||
    (status === EppElementStatusEnumeration.CLOSED && originalAmount !== currentAmount);

  if (!isDisplayable) {
    return null;
  }

  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_CURRENT_AMOUNT_PROPS}
      title={<FormattedMessageComponent id={translations.NEW_LOAN_AMOUNT} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatCurrency(currentAmount)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsCurrentAmountComponent;
