// Contexts
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { FinancingPPIContractingStep3ResumeType } from './types/financing-ppi-contracting-step3-resume.type';

const useFinancingPPIContractingStep3ResumeHook = (): FinancingPPIContractingStep3ResumeType => {
  const { PPIInfo } = useFinancingPPIContextConsumerHook(),
    { ppiPercentage } = PPIInfo || {},
    { profile, localization } = AppContextConsumerHook(),
    { userName } = profile?.userDetails || {},
    { Cobranded_CpiName: productName } = localization?.config || {};

  return {
    productName,
    ppiPercentage,
    userName,
  };
};

export { useFinancingPPIContractingStep3ResumeHook };
