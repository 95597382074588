// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import { FinancingPPIContractingStep1TermsLinkHandlers } from 'containers/financing/views/ppi/components/contracting/components/step1/components/terms-link/handlers/financing-ppi-contracting-step1-terms-link.handlers';
import { FinancingPPIContractingStep1TermsLinkTrackingHandlers } from 'containers/financing/views/ppi/components/contracting/components/step1/components/terms-link/handlers/financing-ppi-contracting-step1-terms-link.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStepFeaturesSectionBuilderReturnHandlersType } from '../handlers/types/financing-ppi-contracting-step1-features-section.handlers.type';

const useFinancingPPIContractingStep1FeaturesSectionHook =
  (): FinancingPPIContractingStepFeaturesSectionBuilderReturnHandlersType => {
    const { setToastConfiguration } = ToastContextConsumerHook();

    return FinancingPPIContractingStep1TermsLinkHandlers({
      ...AppTrackingHook(FinancingPPIContractingStep1TermsLinkTrackingHandlers),
      setToastConfiguration,
    });
  };

export { useFinancingPPIContractingStep1FeaturesSectionHook };
