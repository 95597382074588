// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_CONTRACTING_STEP_1_HIRING_TITLE_PROPS = {
    testId: 'financing-ppi-contracting-step1-hiring-title',
    tag: titleConstants.TAGS.H5,
  },
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_DESCRIPTION_PROPS = {
    bold: true,
    testId: 'financing-ppi-contracting-step1-hiring-description',
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_TITLE_PROPS,
};
