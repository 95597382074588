// Constants
import {
  FINANCING_PPI_LANDING_TAG_CONTRACTED_PROPS,
  FINANCING_PPI_LANDING_TAG_CANCELLED_PROPS,
  FINANCING_PPI_LANDING_TAG_COVERED_PROPS,
} from './constants/financing-ppi-landing-tag.configuration.constants';
// Enumerations
import { PpiStatusEnumeration } from 'containers/financing/views/ppi/enumerations/ppi-status.enumeration';
// Translations
import {
  ACTIVE,
  CANCELLED,
  COVERED,
} from './translations/financing-ppi-landing-tag.configuration.translations';
// Types
import {
  FinancingPPILandingTagBuilderType,
  FinancingPPILandingTagReturnType,
} from './types/financing-ppi-landing-tag.configuration.type';
// Utilities
import { customerHasCurrentlyPPIContracted } from '../utils/financing-ppi-landing.utils';

const getPPILandingTagContractConfiguration = ({
  formatMessage,
  status,
}: FinancingPPILandingTagBuilderType) =>
  [PpiStatusEnumeration.EXPIRED, PpiStatusEnumeration.UNHIRED].includes(
    status as PpiStatusEnumeration
  ) && {
    ...FINANCING_PPI_LANDING_TAG_CANCELLED_PROPS,
    children: formatMessage({ id: CANCELLED }),
  };

const getPPILandingTagCancelConfiguration = ({
  formatMessage,
  status,
}: FinancingPPILandingTagBuilderType) =>
  customerHasCurrentlyPPIContracted(status) && {
    ...FINANCING_PPI_LANDING_TAG_CONTRACTED_PROPS,
    children: formatMessage({ id: ACTIVE }),
  };

const getPPILandingTagCoveredConfiguration = ({
  formatMessage,
  status,
}: FinancingPPILandingTagBuilderType) =>
  [PpiStatusEnumeration.TEMPORAL].includes(status as PpiStatusEnumeration) && {
    ...FINANCING_PPI_LANDING_TAG_COVERED_PROPS,
    children: formatMessage({ id: COVERED }),
  };

const getPPILandingTagConfiguration = (
  props: FinancingPPILandingTagBuilderType
): FinancingPPILandingTagReturnType =>
  getPPILandingTagContractConfiguration(props) ||
  getPPILandingTagCancelConfiguration(props) ||
  getPPILandingTagCoveredConfiguration(props) ||
  {};

export { getPPILandingTagConfiguration };
