// Contexts
import { useFinancingPPIContractingStep1ContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/components/step1/contexts/financing-ppi-contracting-step1.context';
// Event handlers
import { FinancingPPIContractingStep1HiringOptionsHandlers } from '../handlers/financing-ppi-contracting-step1-hiring-options.handlers';
import { FinancingPPIContractingStep1HiringOptionsTrackingHandlers } from '../handlers/financing-ppi-contracting-step1-hiring-options.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep1HiringOptionsType } from './types/financing-ppi-contracting-step1-hiring-options.type';

const useFinancingPPIContractingStep1HiringOptionsHook =
  (): FinancingPPIContractingStep1HiringOptionsType => {
    const { option, setOption, setValidated } =
      useFinancingPPIContractingStep1ContextConsumerHook();

    return {
      ...FinancingPPIContractingStep1HiringOptionsHandlers({
        ...AppTrackingHook(FinancingPPIContractingStep1HiringOptionsTrackingHandlers),
        setOption,
        setValidated,
      }),
      option,
    };
  };

export { useFinancingPPIContractingStep1HiringOptionsHook };
