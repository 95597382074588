// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingStepFeaturesSectionBuilderReturnTrackingHandlersType } from './types/financing-ppi-contracting-step1-features-section.tracking.handlers.type';

const openFinancingPPIContractingConditionsFileLinkClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'termsAndConditions',
    },
    type: TrackingEventsEnum.LINK,
  });

const FinancingPPIContractingStep1TermsLinkTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingStepFeaturesSectionBuilderReturnTrackingHandlersType => ({
  handleOpenFinancingPPIContractingConditionsFileLinkClickTracking: () =>
    track(openFinancingPPIContractingConditionsFileLinkClickTrackingHandler()),
});

export { FinancingPPIContractingStep1TermsLinkTrackingHandlers };
