// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingMessageComponent } from '../message/financing-ppi-contracting-message.component';
import { FinancingPPIContractingDescriptionLinkComponent } from '../description-link/financing-ppi-contracting-description-link.component';
import { FinancingPPIContractingStep2AddressComponent } from './components/address/financing-ppi-contracting-step2-address.component';
import { FinancingPPIContractingStep2ConsentComponent } from './components/consent/financing-ppi-contracting-step2-consent.component';
import { FinancingPPIContractingStep2DownloadComponent } from './components/download/financing-ppi-contracting-step2-download.component';
import { FinancingPPIContractingStep2TNCComponent } from './components/tnc/financing-ppi-contracting-step2-tnc.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CNSPI_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CONTRACT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS,
} from './constants/financing-ppi-contracting-step2.constants';
import { FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS } from '../../constants/financing-ppi-contracting.constants';
// Contexts
import { FinancingPPIContractingStep2ContextConsumerHOC } from './contexts/financing-ppi-contracting-step2.context';
// Hooks
import { useFinancingPPIContractingStep2Hook } from './hooks/financing-ppi-contracting-step2.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { DESCRIPTION } from './translations/financing-ppi-contracting-step2.translations';

const FinancingPPIContractingStep2BaseComponent = (): React.ReactElement => {
  const {
    handleValidateChecksAndDownloadButtonClick: onClick,
    errorChecksDone: error,
    fetching: loading,
    url,
  } = useFinancingPPIContractingStep2Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS}>
        <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <FinancingPPIContractingStep2DownloadComponent />
        <FinancingPPIContractingStep2AddressComponent />
        <FinancingPPIContractingStep2ConsentComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CNSPI_PROPS}
        />
        <FinancingPPIContractingStep2TNCComponent />
        <FinancingPPIContractingStep2ConsentComponent
          {...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_CONTRACT_PROPS}
        />
        <FinancingPPIContractingMessageComponent
          {...{ ...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS, error }}
        />
        <FinancingPPIContractingDescriptionLinkComponent
          {...{ ...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS, url }}
        />
      </LayoutColumnFlexComponentStyled>
      <WizardStepFooterComponent
        {...FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ disabled: loading, loading, onClick }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

const FinancingPPIContractingStep2Component = FinancingPPIContractingStep2ContextConsumerHOC(
  FinancingPPIContractingStep2BaseComponent
);

export { FinancingPPIContractingStep2Component };
