// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingStep1HiringOptionsTrackingHandlersType } from './types/financing-ppi-contracting-step1-hiring-options.tracking.handlers.type';

const clickChoosePPIAgreementCheckTrackingHandler = (
  option: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: option ? 'yesIDo' : 'NoIDoNot',
  },
  type: TrackingEventsEnum.LINK,
});

const FinancingPPIContractingStep1HiringOptionsTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingStep1HiringOptionsTrackingHandlersType => ({
  handleChoosePPIAgreementCheckClickTracking: (option: boolean) =>
    track(clickChoosePPIAgreementCheckTrackingHandler(option)),
});

export { FinancingPPIContractingStep1HiringOptionsTrackingHandlers };
