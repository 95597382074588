// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-nominal-interest-rate.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';
// Translations
import translations from './translations/financing-landing-row-details-nominal-interest-rate.translations';
// Types
import { FinancingLandingRowDetailsNominalInterestRateComponentType } from './types/financing-landing-row-details-nominal-interest-rate.component.type';

const FinancingLandingRowDetailsNominalInterestRateComponent = ({
  interestRate,
}: FinancingLandingRowDetailsNominalInterestRateComponentType): React.ReactElement => {
  const { formatPercentage } = FinancingLandingTableRowDetailsUtils();
  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_INTEREST_RATE_PROPS}
      title={<FormattedMessageComponent id={translations.INTEREST_RATE} />}
      showTitleIcon
      tooltipConfiguration={{
        content: <FormattedMessageComponent id={translations.INTEREST_RATE_INFO} />,
      }}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatPercentage(interestRate)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsNominalInterestRateComponent;
