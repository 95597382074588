// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_TITLE_PROPS = {
    testId: 'financing-ppi-contracting-step1-coverage-title',
    tag: titleConstants.TAGS.H5,
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_TITLE_PROPS,
};
