// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { LoginLinksBuilderReturnTrackingHandlersType } from 'containers/login/components/links/handlers/types/login-links-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const openLoginEmailLinkClickTrackingHandler = (href: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'linkTo:' + href,
  },
  type: TrackingEventsEnum.LINK,
});

const LoginLinksTrackingHandlers = (
  track: TrackBuilderType
): LoginLinksBuilderReturnTrackingHandlersType => ({
  handleOpenLoginEmailLinkClickTracking: (href: string) =>
    track(openLoginEmailLinkClickTrackingHandler(href)),
});

export default LoginLinksTrackingHandlers;
