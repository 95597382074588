// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Hooks
import LoginLinksHook from './hooks/login-links.hook';
// Types
import { LoginLinkItemType } from './types/login-link-item.type';
import { LoginEmailLinkItemType } from './types/login-links.component.type';
// Utilities
import {
  LoginLinksComponentStyled,
  LoginEmailLinksItemComponentStyled,
} from './login-links.component.styled';

const LoginLinksComponent = ({
  links,
  fetching,
}: LoginEmailLinkItemType): React.ReactElement<LoginEmailLinkItemType> => {
  const { handleOpenLoginEmailLinkClick } = LoginLinksHook();

  return (
    <LoginLinksComponentStyled>
      {links.map(
        (
          { href, isExternal, text, testId }: LoginLinkItemType,
          key: number
        ): React.ReactElement => (
          <LoginEmailLinksItemComponentStyled key={key}>
            <LinkComponent
              {...{ testId }}
              disabled={fetching}
              onClick={() => handleOpenLoginEmailLinkClick({ href, isExternal })}
            >
              <FormattedMessageComponent id={text} />
            </LinkComponent>
          </LoginEmailLinksItemComponentStyled>
        )
      )}
    </LoginLinksComponentStyled>
  );
};

export default LoginLinksComponent;
