// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Constants
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import {
  FINANCING_PPI_LANDING_CARD_BASE_PROPS,
  FINANCING_PPI_LANDING_CARD_FIRST_ICON,
  FINANCING_PPI_LANDING_CARD_SECOND_ICON,
  FINANCING_PPI_PERCENTAGE_PROMILE_BASE,
} from './constants/financing-ppi-landing.configuration.constants';
// Translations
import {
  DESCRIPTION,
  FIRST_ITEM_TITLE,
  FIRST_ITEM_DESCRIPTION,
  SECOND_ITEM_TITLE,
  SECOND_ITEM_DESCRIPTION,
  TITLE,
} from './translations/financing-ppi-landing.configuration.translations';
// Types
import { FinancingPPILandingBaseBuilderType } from './types/financing-ppi-landing-base.configuration.type';
import { FinancingPPILandingConfigurationType } from './types/financing-ppi-landing.configuration.type';

const getPPILandingCardBaseConfiguration = ({
  formatMessage,
  ppiPercentage,
}: FinancingPPILandingBaseBuilderType): FinancingPPILandingConfigurationType => ({
  ...FINANCING_PPI_LANDING_CARD_BASE_PROPS,
  description: (
    <FormattedMessageComponent
      id={DESCRIPTION}
      values={{
        ppiPercentagePromile: <FormattedNumber value={Number(ppiPercentage) * FINANCING_PPI_PERCENTAGE_PROMILE_BASE} />,
        ppiPercentage: <FormattedNumber value={Number(ppiPercentage)} />,
      }}
    />
  ),
  items: [
    {
      ...FINANCING_PPI_LANDING_CARD_FIRST_ICON,
      description: formatMessage({ id: FIRST_ITEM_DESCRIPTION }),
      title: formatMessage({ id: FIRST_ITEM_TITLE }),
    },
    {
      ...FINANCING_PPI_LANDING_CARD_SECOND_ICON,
      title: formatMessage({ id: SECOND_ITEM_TITLE }),
      description: formatMessage({ id: SECOND_ITEM_DESCRIPTION }),
    },
  ],
  title: formatMessage({ id: TITLE }),
});

export { getPPILandingCardBaseConfiguration };
