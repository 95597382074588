// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ExpandableRowComponent, ListComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep1CoverageLinkComponent } from '../link/financing-ppi-contracting-step1-coverage-link.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_DESCRIPTION_01_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_DESCRIPTION_02_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_EXPANDABLE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_ITEMS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS__LAYOUT_PROPS,
} from './constants/financing-ppi-contracting-step1-coverage-limitations.constants';
// Hooks
import { useFinancingPPIContractingStep1TranslateHook } from 'containers/financing/views/ppi/components/contracting/components/step1/hooks/financing-ppi-contracting-step1-translate.hook';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  TITLE,
} from './translations/financing-ppi-contracting-step1-coverage-limitations.translations';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Utilities
import { mapAndTranslateSectionsListItems } from '../../utils/financing-ppi-contracting-step1-coverage.utils';

const FinancingPPIContractingStep1CoverageLimitationsComponent = (): React.ReactElement => {
  const { formatMessage } = useFinancingPPIContractingStep1TranslateHook();

  return (
    <ExpandableRowComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_EXPANDABLE_PROPS}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <LayoutColumnFlexComponentStyled
        {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS__LAYOUT_PROPS}
      >
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_DESCRIPTION_01_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_01} />
        </ParagraphComponent>
        <ListComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_PROPS}
          items={mapAndTranslateSectionsListItems({
            ...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_ITEMS,
            formatMessage,
          })}
        />
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_DESCRIPTION_02_PROPS}
        >
          <FormattedMessageComponent
            id={DESCRIPTION_02}
            values={{
              link: (chunks: string | React.ReactNode) => (
                <FinancingPPIContractingStep1CoverageLinkComponent {...{ chunks }} />
              ),
            }}
          />
        </ParagraphComponent>
      </LayoutColumnFlexComponentStyled>
    </ExpandableRowComponent>
  );
};

export { FinancingPPIContractingStep1CoverageLimitationsComponent };
