// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
// Types
import { FinancingPPICancellationLandingResumeCoveredHookType } from './types/financing-ppi-cancellation-landing-resume-covered.hook.type';
import { AppContextConsumerHook } from 'contexts/app.context';

const useFinancingPPICancellationLandingResumeCoveredHook =
  (): FinancingPPICancellationLandingResumeCoveredHookType => {
    const { PPIInfo } = useFinancingPPIContextConsumerHook(),
      { status } = PPIInfo || {},
      { currentCard } = AppContextConsumerHook(),
      { nextCycleDate } = currentCard?.creditDetails || {};

    const { formatDate } = useCbIntl();

    return {
      formatDate,
      nextCycleDate,
      status,
    };
  };

export { useFinancingPPICancellationLandingResumeCoveredHook };
