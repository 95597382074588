// Vendors
import React from 'react';
// Enumerations
import { LoginViewsEnumeration } from '../../enumerations/login-views.enumeration';
// Components
import LoginEmailView from '../../views/email/login-email.view';
import LoginErrorView from '../../views/error/login-error.view';
import LoginGapsView from '../../views/gaps/login-gaps.view';
// Contexts
import { LoginContextConsumerHook } from '../../contexts/login.context';

const LoginOrchestratorComponent = (): React.ReactElement | null => {
  const { loginView } = LoginContextConsumerHook();

  switch (loginView) {
    case LoginViewsEnumeration.EMAIL:
      return <LoginEmailView />;
    case LoginViewsEnumeration.GAPS:
      return <LoginGapsView />;
    case LoginViewsEnumeration.ERROR:
      return <LoginErrorView />;
    default:
      return null;
  }
};
export default LoginOrchestratorComponent;
