// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-original-amount.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Translations
import translations from './translations/financing-landing-row-details-original-amount.translations';
// Types
import { FinancingLandingRowDetailsOriginalAmountComponentType } from './types/financing-landing-row-details-original-amount.component.type';
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';

const FinancingLandingRowDetailsOriginalAmountComponent = ({
  originalAmount,
}: FinancingLandingRowDetailsOriginalAmountComponentType): React.ReactElement => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();
  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_ORIGINAL_AMOUNT_PROPS}
      title={<FormattedMessageComponent id={translations.ORIGINAL_AMOUNT} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatCurrency(originalAmount)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsOriginalAmountComponent;
