// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { useFinancingPPIContractingStep1ContextConsumerHook } from '../contexts/financing-ppi-contracting-step1.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Event handlers
import { FinancingPPIContractingStep1Handlers } from '../handlers/financing-ppi-contracting-step1.handlers';
// Types
import { FinancingPPIContractingStep1MethodsHandlersType } from '../handlers/types/financing-ppi-contracting-step1.handlers.type';

const useFinancingPPIContractingStep1Hook = (): FinancingPPIContractingStep1MethodsHandlersType => {
  const { option, setValidated } = useFinancingPPIContractingStep1ContextConsumerHook(),
    { setCurrentStep } = useWizardContextConsumerHook();

  return FinancingPPIContractingStep1Handlers({
    navigate: useNavigate(),
    option,
    setCurrentStep,
    setValidated,
  });
};

export { useFinancingPPIContractingStep1Hook };
