// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, TagComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import {
  AMORTIZATION_MODAL_COMPONENT_PROPS,
  AMORTIZATION_TABLE_STATUS_TAG_TYPES,
} from '../../constants/amortization-modal.component.constants';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from 'containers/financing/views/landing/components/row/components/details/utils/financing-landing-table-row-details.utils';
// Styles
import {
  AmortizationModalTitleStyled,
  FirstSection,
  FormatDateStyled,
  InstallmentsDetailStyled,
  InstallmentsStyled,
} from './amortization-modal-mobile.component.style';
// Translations
import { DESCRIPTIONS } from '../../translations/amortization-modal.translations';
import translations from './translations/amortization-modal.component.translations';
// Types
import { AmortizationModalComponentType } from '../../types/amortization-modal.component.type';
// Utils
import { isEmphasizedTag, isValidateTag } from '../../utils/amortization-modal.component.utils';
import BoldMessageComponent from 'components/bold-message/bold-message.component';

const AmortizationModalMobileComponent = ({
  installments,
}: AmortizationModalComponentType): React.ReactElement => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();

  return (
    <>
      {installments.map(installment => (
        <>
          <AmortizationModalTitleStyled>
            <FirstSection>
              <ParagraphComponent {...AMORTIZATION_MODAL_COMPONENT_PROPS}>
                <FormattedMessageComponent id={translations.INSTALMENT} />
                <FormattedMessageComponent id={`${installment.term}/${installments.length}`} />
              </ParagraphComponent>
              {isValidateTag(installment?.status) ? (
                <TagComponent
                  isEmphasizedTag={isEmphasizedTag(
                    AMORTIZATION_TABLE_STATUS_TAG_TYPES[installment.status]
                  )}
                  type={AMORTIZATION_TABLE_STATUS_TAG_TYPES[installment.status]}
                >
                  <FormattedMessageComponent uppercase id={DESCRIPTIONS[installment.status]} />
                </TagComponent>
              ) : null}
            </FirstSection>
            <FormatDateStyled>
              <FormattedDate
                {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
                value={installment.dueDate}
              />
            </FormatDateStyled>
          </AmortizationModalTitleStyled>
          <InstallmentsStyled>
            <InstallmentsDetailStyled>
              <BoldMessageComponent>
                <FormattedMessageComponent id={translations.PENDING_AMOUNT} />
              </BoldMessageComponent>
              <BoldMessageComponent>
                {formatCurrency(installment.outstandingCapital)}
              </BoldMessageComponent>
            </InstallmentsDetailStyled>
            <InstallmentsDetailStyled>
              <FormattedMessageComponent id={translations.TOTAL_PAID} />
              <FormattedMessageComponent id={formatCurrency(installment.dueAmount)} />
            </InstallmentsDetailStyled>
            <InstallmentsDetailStyled>
              <FormattedMessageComponent id={translations.INSTALMENT_AMOUNT} />
              <ParagraphComponent>{formatCurrency(installment.capitalAmount)}</ParagraphComponent>
            </InstallmentsDetailStyled>
            <InstallmentsDetailStyled>
              <FormattedMessageComponent id={translations.INTERESTS} />
              <ParagraphComponent>{formatCurrency(installment.interestAmount)}</ParagraphComponent>
            </InstallmentsDetailStyled>
          </InstallmentsStyled>
        </>
      ))}
    </>
  );
};

export default AmortizationModalMobileComponent;
