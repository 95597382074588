// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Types
import {
  FinancingPPIContractingStep2BuilderHandlersType,
  FinancingPPIContractingStep2FetchHandlersType,
  FinancingPPIContractingStep2MethodsHandlersType,
} from './types/financing-ppi-contracting-step2.handlers.type';
// Utilities
import {
  allMandatoryActionsAreDone,
  allMandatoryChecksAreChecked,
  getTermsAndConditionsParams,
} from './utils/financing-ppi-contracting-step2.handlers.utils';
import { savePPITermsAndConditionsResolver } from 'containers/financing/views/ppi/components/contracting/components/step2/resolvers/financing-ppi-contracting-step2.resolver';

const saveTermsAndConditionsOnApi = async ({
  formatMessage,
  setCurrentStep,
  setFetching,
  setResult,
}: FinancingPPIContractingStep2FetchHandlersType): Promise<void> => {
  setFetching(true);

  const [, error] = await savePPITermsAndConditionsResolver(
    getTermsAndConditionsParams(formatMessage)
  );

  error ? setResult(false) : setCurrentStep(WizardStepsEnumeration.THREE);
};

const validateChecksAndDownloadButtonClickHandler = ({
  checksDone,
  setErrorChecksDone,
  setErrorDocumentDownloaded,
  documentDownloaded,
  ...rest
}: FinancingPPIContractingStep2BuilderHandlersType): void => {
  setErrorChecksDone(false);
  setErrorDocumentDownloaded(false);

  !allMandatoryChecksAreChecked(checksDone) && setErrorChecksDone(true);
  !documentDownloaded && setErrorDocumentDownloaded(true);

  allMandatoryActionsAreDone({ checksDone, documentDownloaded }) &&
    saveTermsAndConditionsOnApi(rest);
};

const FinancingPPIContractingStep2Handlers = (
  props: FinancingPPIContractingStep2BuilderHandlersType
): FinancingPPIContractingStep2MethodsHandlersType => ({
  handleValidateChecksAndDownloadButtonClick: () =>
    validateChecksAndDownloadButtonClickHandler(props),
});

export { FinancingPPIContractingStep2Handlers };
