// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/financing-landing-row-details-amount-pending.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';
// Translations
import translations from './translations/financing-landing-row-details-amount-pending.translations';
// Types
import { FinancingLandingRowDetailsAmountPendingComponentType } from './types/financing-landing-row-details-amount-pending.component.type';
import { getDisplayValue } from './utils/financing-landing-row-details-amount-pending.component.utils';

const FinancingLandingRowDetailsAmountPendingComponent = ({
  pendingAmount,
  status,
}: FinancingLandingRowDetailsAmountPendingComponentType): React.ReactElement => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();
  const displayValue = getDisplayValue({ formatCurrency, pendingAmount, status });

  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_AMOUNT_PENDING_PROPS}
      title={<FormattedMessageComponent id={translations.AMOUNT_PENDING} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={displayValue} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsAmountPendingComponent;
