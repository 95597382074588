const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step2.';

const DESCRIPTION = `${DOMAIN}termsAndConditions.description`,
  TERMS_ACCEPTANCE_CONSENT = `${DOMAIN}termsAcceptance.cbCheckBox.label`,
  CNSPI_CONSENT = `${DOMAIN}cnspi.cbCheckBox.label`,
  MESSAGE = `${DOMAIN}consents.cbMessage.description`,
  LEGAL_TEXT = `${DOMAIN}legalText`,
  TITLE = `${DOMAIN}title`;

export { TERMS_ACCEPTANCE_CONSENT, CNSPI_CONSENT, DESCRIPTION, LEGAL_TEXT, MESSAGE, TITLE };
