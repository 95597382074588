// Enumerations
import { PpiStatusEnumeration } from 'containers/financing/views/ppi/enumerations/ppi-status.enumeration';
// Translations
import {
  STATUS,
  VALID_UNTIL,
} from './translations/financing-ppi-landing-status.configuration.translations';
// Types
import {
  FinancingPPILandingStatusBuilderType,
  FinancingPPILandingStatusReturnType,
} from './types/financing-ppi-landing-status.configuration.type';
// Utilities
import { getPPIEndCoverageDate } from './utils/financing-ppi-landing-status.configuration.utils';

const getPPILandingStatusConfiguration = ({
  formatDate,
  formatMessage,
  endCoverageDate,
  status,
}: FinancingPPILandingStatusBuilderType): FinancingPPILandingStatusReturnType => {
  const RESPONSE = { title: formatMessage({ id: STATUS }) };

  switch (status) {
    case PpiStatusEnumeration.TEMPORAL:
      const date = getPPIEndCoverageDate({ endCoverageDate, formatDate });

      return { ...RESPONSE, subtitle: formatMessage({ id: VALID_UNTIL }, { date }) };
    default:
      return RESPONSE;
  }
};

export { getPPILandingStatusConfiguration };
