// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { PathsCommonEnum } from '@openbank/cf-ui-static-data';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Views
import { FinancingPPILandingComponent } from '../components/landing/financing-ppi-landing.component';
import { FinancingPPICancellationComponent } from '../components/cancellation/financing-ppi-cancellation.component';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const FINANCING_PPI_COMMON_ROUTES: RouteType[] = [
    {
      element: FinancingPPICancellationComponent,
      props: { path: RoutesEnumeration.PPI_CANCELLATION },
    },
    { element: FinancingPPILandingComponent, props: { index: true } },
  ],
  FINANCING_ANY_ROUTE_PROPS = {
    path: PathsCommonEnum.ANY,
  },
  FINANCING_ANY_NAVIGATE_ROUTE_PROPS = {
    replace: true,
    to: generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PPI])
    ),
  };

export {
  FINANCING_PPI_COMMON_ROUTES,
  FINANCING_ANY_NAVIGATE_ROUTE_PROPS,
  FINANCING_ANY_ROUTE_PROPS,
};
