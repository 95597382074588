// Enumerations
import { InstallmentsStatusEnumeration } from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/enumerations/epp-element-status.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.financingLanding.cbAmortizationModal.status.';

const DESCRIPTIONS = {
  [InstallmentsStatusEnumeration.CANCELLED]: `${DOMAIN}cancelled`,
  [InstallmentsStatusEnumeration.LIQUIDATED]: `${DOMAIN}settled`,
  [InstallmentsStatusEnumeration.PENDING]: `${DOMAIN}pending`,
  [InstallmentsStatusEnumeration.RETURNED]: `${DOMAIN}returned`,
};

export { DESCRIPTIONS };
