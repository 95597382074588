// Vendors
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';
import useCbIntl from 'hooks/useCbIntl';

const useFinancingPPIContractingStep1TranslateHook = (): Pick<
  UseCbIntlReturnType,
  'formatMessage'
> => {
  const { formatMessage } = useCbIntl();

  return { formatMessage };
};

export { useFinancingPPIContractingStep1TranslateHook };
