// Vendors
import React from 'react';
// Components
import FinancingLandingRowDetailsAmortizationTableComponent from './components/amortizationTable/financing-landing-row-details-amortization-table.component';
import FinancingLandingRowDetailsAmountPendingComponent from './components/amountPending/financing-landing-row-details-amount-pending.component';
import FinancingLandingRowDetailsAnnualPercentageComponent from './components/annualPercentage/financing-landing-row-details-annual-percentage.component';
import FinancingLandingRowDetailsAmountRepaidEarlierComponent from './components/amountRepaidEarlier/financing-landing-row-details-amount-repaid-earlier.component';
import FinancingLandingRowDetailsCancellationDateComponent from './components/cancellationDate/financing-landing-row-details-cancellation-date.component';
import FinancingLandingRowDetailsCreationDateComponent from './components/creationDate/financing-landing-row-details-creation-date.component';
import FinancingLandingRowDetailsCurrentAmountComponent from './components/currentAmount/financing-landing-row-details-current-amount.component';
import FinancingLandingRowDetailsEarlyRepaymentLinkComponent from './components/earlyRepaymentLink/financing-landing-row-details-early-repayment-link.component';
import FinancingLandingRowDetailsEarlyRepaymentDateComponent from './components/earlyRepaymentDate/financing-landing-row-details-early-repayment-date.component';
import FinancingLandingRowDetailsNominalInterestRateComponent from './components/nominalInterestRate/financing-landing-row-details-nominal-interest-rate.component';
import FinancingLandingRowDetailsOriginalAmountComponent from './components/originalAmount/financing-landing-row-details-original-amount.component';
import FinancingLandingRowDetailsStatusMessageComponent from './components/statusMessage/financing-landing-row-details-status-message.component';
import FinancingLandingRowDetailsTotalDueComponent from './components/totalDue/financing-landing-row-details-total-due.component';
import FinancingLandingRowDetailsTotalInterestComponent from './components/totalInterest/financing-landing-row-details-total-interest.component';
import { FinancingLandingRowDetailsOrdersLinkComponent } from './components/orders/financing-landing-row-details-orders-link.component';
// Styles
import { FinancingLandingRowDetailsComponentStyled } from './financing-landing-table-row-details.component.styled';
// Types
import { FinancingLandingRowDetailsComponentType } from './types/financing-landing-table-row-details.component.type';

const FinancingLandingRowDetailsComponent = ({
  element,
  open,
}: FinancingLandingRowDetailsComponentType): React.ReactElement | null =>
  open ? (
    <FinancingLandingRowDetailsComponentStyled>
      <FinancingLandingRowDetailsCreationDateComponent {...element} />
      <FinancingLandingRowDetailsCancellationDateComponent {...element} />
      <FinancingLandingRowDetailsEarlyRepaymentDateComponent {...element} />
      <FinancingLandingRowDetailsOriginalAmountComponent {...element} />
      <FinancingLandingRowDetailsCurrentAmountComponent {...element} />
      <FinancingLandingRowDetailsAmountRepaidEarlierComponent {...element} />
      <FinancingLandingRowDetailsAmountPendingComponent {...element} />
      <FinancingLandingRowDetailsTotalDueComponent {...element} />
      <FinancingLandingRowDetailsTotalInterestComponent
        totalInterestAmount={element.amortizationTable.totalInterestAmount}
      />
      <FinancingLandingRowDetailsAnnualPercentageComponent {...element} />
      <FinancingLandingRowDetailsNominalInterestRateComponent
        interestRate={element.nominalInterestRate}
      />
      <FinancingLandingRowDetailsAmortizationTableComponent
        installments={element.amortizationTable.installments}
      />
      <FinancingLandingRowDetailsEarlyRepaymentLinkComponent {...element} />
      <FinancingLandingRowDetailsOrdersLinkComponent {...element} />
      <FinancingLandingRowDetailsStatusMessageComponent {...element} />
    </FinancingLandingRowDetailsComponentStyled>
  ) : null;

export default FinancingLandingRowDetailsComponent;
