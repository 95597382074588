// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-total-interest.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Translations
import translations from './translations/financing-landing-row-details-total-interest.translations';
// Types
import { FinancingLandingRowDetailsTotalInterestComponentType } from './types/financing-landing-row-details-total-interest.component.type';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';

const FinancingLandingRowDetailsTotalInterestComponent = ({
  totalInterestAmount,
}: FinancingLandingRowDetailsTotalInterestComponentType): React.ReactElement => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();
  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_TOTAL_INTEREST_PROPS}
      showTitleIcon
      tooltipConfiguration={{
        content: <FormattedMessageComponent id={translations.TOTAL_INTEREST_INFO} />,
      }}
      title={<FormattedMessageComponent id={translations.TOTAL_INTEREST} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatCurrency(totalInterestAmount)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsTotalInterestComponent;
