// Constants
import { assetConstants, buttonConstants } from '@openbank/cb-ui-commons';

export default {
  LOGIN_HEADER_ASSET_PROPS: {
    sizes: {
      width: 185,
      height: 40,
    },
    type: assetConstants.TYPES.LOGO,
    testId: 'login-dr-picasso-header-asset',
  },
  LOGIN_HEADER_BUTTON_PROPS: {
    rwdFull: false,
    testId: 'login-dr-picasso-header-links',
    type: buttonConstants.TYPES.TERTIARY,
  },
  LOGIN_HEADER_PRIVATE_PROD_URL: 'https://www.amazon.zinia.de',
};
