// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import {
  ORDER_MOBILE_LINK_CONTANTS,
  ORDER_MOBILE_TABLE_DETAILS_BODY,
  ORDER_MOBILE_TABLE_DETAILS_HEADER,
  ORDER_MODAL_MOBILE_CONTANTS,
} from './constants/order-modal-mobile.component.constants';
// Hooks
import { OrderModalHooks } from '../../hooks/order-modal.hook';
// Styles
import {
  InstallmentsDetailStyled,
  InstallmentsStyled,
  OrderMobileModalWrapperStyled,
  OrderModalTitleStyled,
  OrderListWrapperStyled,
} from './order-modal-mobile.component.styled';
// Translations
import {
  DEALER_LABEL,
  DEALER_NAME,
  LINK_LABEL,
  ORDER_NUMBER,
  PURCHASE_DATE,
  VIEW_DETAIL_LINK,
} from '../../transalations/order-modal.translations';
// Types
import { OrderModalComponentProps } from '../../types/order-modal.component.types';

const OrderModalMobileComponent = ({
  orders,
  urlAmazon,
  transactionInfo: { date },
}: OrderModalComponentProps): React.ReactElement => {
  const { handleViewDetailLink } = OrderModalHooks(urlAmazon);

  return (
    <OrderMobileModalWrapperStyled {...ORDER_MODAL_MOBILE_CONTANTS}>
      <OrderModalTitleStyled {...ORDER_MOBILE_TABLE_DETAILS_HEADER}>
        <FormattedMessageComponent id={PURCHASE_DATE} />
        <ParagraphComponent>
          <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM} value={date} />
        </ParagraphComponent>
      </OrderModalTitleStyled>
      <InstallmentsStyled {...ORDER_MOBILE_TABLE_DETAILS_BODY}>
        <InstallmentsDetailStyled>
          <FormattedMessageComponent id={DEALER_LABEL} />
          <FormattedMessageComponent id={DEALER_NAME} />
        </InstallmentsDetailStyled>
        <InstallmentsDetailStyled>
          <FormattedMessageComponent id={ORDER_NUMBER} />
          <OrderListWrapperStyled>
            {orders?.map(order => (
              <ParagraphComponent key={order}>{order}</ParagraphComponent>
            ))}
          </OrderListWrapperStyled>
        </InstallmentsDetailStyled>
        <InstallmentsDetailStyled>
          <FormattedMessageComponent id={LINK_LABEL} />
          <LinkComponent {...ORDER_MOBILE_LINK_CONTANTS} onClick={handleViewDetailLink}>
            <FormattedMessageComponent id={VIEW_DETAIL_LINK} />
          </LinkComponent>
        </InstallmentsDetailStyled>
      </InstallmentsStyled>
    </OrderMobileModalWrapperStyled>
  );
};

export { OrderModalMobileComponent };
