// Vendors
import styled from 'styled-components';
// Utils
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

const AmortizationModalTitleStyled = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: flex-start;
  padding: ${convertPxsToRems({ pixels: 8, base: 14 })}
    ${convertPxsToRems({
      pixels: 6,
      base: 14,
    })};
  width: 100%;

  ${mobileAndTabletMediaQuery} {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    gap: 8px;
    justify-content: flex-start;
    margin-top: ${convertPxsToRems({ pixels: 16, base: 14 })};
    width: 100%;
  }
`;

const FirstSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  justify-content: flex-start;
  padding: ${convertPxsToRems({ pixels: 6, base: 14 })} ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${tabletMediaQuery} {
    display: flex;
    flex-direction: row;
  }
`;

export const FormatDateStyled = styled.div`
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyB};
  font-size: ${({ theme }) => theme.fontSizes.fontSizeP};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding: ${convertPxsToRems({ pixels: 6, base: 14 })} ${({ theme }) => theme.spaces.spacingS};
  text-align: right;
  width: 100%;
`;
const InstallmentsDetailStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const InstallmentsStyled = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyB};
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS} 0;
`;

export { AmortizationModalTitleStyled, FirstSection, InstallmentsDetailStyled, InstallmentsStyled };
