// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LINK_PROPS } from './constants/financing-ppi-contracting-step1-coverage-link.constants';
// Hooks
import { useFinancingPPIContractingStep1CoverageLinkHook } from './hooks/financing-ppi-contracting-step1-coverage-link.hook';
// Types
import { FinancingPPIContractingStep1CoverageLinkType } from './types/financing-ppi-contracting-step1-coverage-link.type';

const FinancingPPIContractingStep1CoverageLinkComponent = ({
  chunks,
}: FinancingPPIContractingStep1CoverageLinkType): React.ReactElement | null => {
  const { handleOpenPPIContractingTermsAndConditionsLinkClick } =
    useFinancingPPIContractingStep1CoverageLinkHook();

  return (
    <LinkComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LINK_PROPS}
      onClick={handleOpenPPIContractingTermsAndConditionsLinkClick}
    >
      {chunks}
    </LinkComponent>
  );
};

export { FinancingPPIContractingStep1CoverageLinkComponent };
