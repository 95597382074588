// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { FinancingPPIContractingStep1CoverageLinkHandlers } from '../handlers/financing-ppi-contracting-step1-coverage-link.handlers';
import { FinancingPPIContractingStep1CoverageLinkTrackingHandlers } from '../handlers/financing-ppi-contracting-step1-coverage-link.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType } from '../handlers/types/financing-ppi-contracting-step1-coverage-description-link.handlers.type';

const useFinancingPPIContractingStep1CoverageLinkHook =
  (): FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType => {
    const { localization } = AppContextConsumerHook(),
      { cfg_public_cpi_general_tnc: url } = localization?.config || {};

    return FinancingPPIContractingStep1CoverageLinkHandlers({
      ...AppTrackingHook(FinancingPPIContractingStep1CoverageLinkTrackingHandlers),
      url,
    });
  };

export { useFinancingPPIContractingStep1CoverageLinkHook };
