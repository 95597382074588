// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { OrderModalMobileComponent } from './components/mobile/order-modal-mobile.component';
import { OrderModalDesktopComponent } from './components/desktop/order-modal-desktop.component';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Translations
import { TITLE } from './transalations/order-modal.translations';
// Types
import { CustomModal } from './order-modal-orchestrator.component.styled';
import { OrderModalOrchestratorComponentProps } from './types/order-modal-orquestrator.component.types';
import { OrderModalComponentProps } from './types/order-modal.component.types';

const OrderModalOrchestratorComponent = ({
  open,
  handleModal,
  ...rest
}: OrderModalOrchestratorComponentProps): React.ReactElement<OrderModalComponentProps> | null => {
  const { isMobile } = DeviceTypeHook();
  return open ? (
    <CustomModal onHeaderClose={handleModal} title={<FormattedMessageComponent id={TITLE} />}>
      {isMobile ? (
        <OrderModalMobileComponent {...(rest as OrderModalComponentProps)} />
      ) : (
        <OrderModalDesktopComponent {...(rest as OrderModalComponentProps)} />
      )}
    </CustomModal>
  ) : null;
};

export { OrderModalOrchestratorComponent };
