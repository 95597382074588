// Vendors
import styled from 'styled-components';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const CustomModal = styled(ModalComponent)`
  max-width: ${convertPxsToRems({ pixels: 648, base: 14 })};
`;

CustomModal.displayName = 'CustomModal';

export { CustomModal };
