// Types
import {
  FinancingPPIContractingStep1CoverageDescriptionLinkBuilderHandlersType,
  FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType,
} from './types/financing-ppi-contracting-step1-coverage-description-link.handlers.type';

const openPPITermsAndConditionsLinkClickHandler = ({
  handleOpenPPIContractingTermsAndConditionsLinkClickTracking,
  url,
}: FinancingPPIContractingStep1CoverageDescriptionLinkBuilderHandlersType): void => {
  handleOpenPPIContractingTermsAndConditionsLinkClickTracking();
  window.open(url);
};

const FinancingPPIContractingStep1CoverageLinkHandlers = (
  props: FinancingPPIContractingStep1CoverageDescriptionLinkBuilderHandlersType
): FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType => ({
  handleOpenPPIContractingTermsAndConditionsLinkClick: () =>
    openPPITermsAndConditionsLinkClickHandler(props),
});

export { FinancingPPIContractingStep1CoverageLinkHandlers };
