// Constants
import { ERROR_TOAST_CONFIGURATION } from './constants/financing-ppi-landing.handlers.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Resolvers
import { fetchTermsAndPolicyDocumentResolver } from '../resolvers/financing-ppi-landing.resolver';
// Types
import {
  FinancingPPILandingBuilderHandlersType,
  FinancingPPILandingMethodsHandlersType,
} from './types/financing-ppi-landing.handlers.type';

const cancelPPIContractButtonClickHandler = ({
  handleCancelPPIContractButtonClickTracking,
  navigate,
}: Pick<
  FinancingPPILandingBuilderHandlersType,
  'handleCancelPPIContractButtonClickTracking' | 'navigate'
>): void => {
  handleCancelPPIContractButtonClickTracking();
  navigate(RoutesEnumeration.PPI_CANCELLATION);
};

const contractPPIButtonClickHandler = ({
  handleContractPPIButtonClickTracking,
  navigate,
}: Pick<
  FinancingPPILandingBuilderHandlersType,
  'handleContractPPIButtonClickTracking' | 'navigate'
>): void => {
  handleContractPPIButtonClickTracking();
  navigate(RoutesEnumeration.PPI_CONTRACTING);
};

const viewTermsPolicyLinkClickTrackingHandler = async ({
  handleViewTermsPolicyLinkClickTracking,
  setToastConfiguration,
}: Pick<
  FinancingPPILandingBuilderHandlersType,
  'handleViewTermsPolicyLinkClickTracking' | 'setToastConfiguration'
>): Promise<void> => {
  handleViewTermsPolicyLinkClickTracking();

  const [response] = await fetchTermsAndPolicyDocumentResolver();

  response ? window.open(response) : setToastConfiguration(ERROR_TOAST_CONFIGURATION);
};

const FinancingPPILandingHandlers = ({
  handleCancelPPIContractButtonClickTracking,
  handleContractPPIButtonClickTracking,
  handleViewTermsPolicyLinkClickTracking,
  navigate,
  setToastConfiguration,
}: FinancingPPILandingBuilderHandlersType): FinancingPPILandingMethodsHandlersType => ({
  handleCancelPPIContractButtonClick: () =>
    cancelPPIContractButtonClickHandler({ handleCancelPPIContractButtonClickTracking, navigate }),
  handleContractPPIButtonClick: () =>
    contractPPIButtonClickHandler({ handleContractPPIButtonClickTracking, navigate }),
  handleViewTermsPolicyLinkClick: () =>
    viewTermsPolicyLinkClickTrackingHandler({
      handleViewTermsPolicyLinkClickTracking,
      setToastConfiguration,
    }),
});

export { FinancingPPILandingHandlers };
