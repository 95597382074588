// Translations
import {
  VIEW_POLICY,
  VIEW_TERMS_AND_CONDITIONS,
} from './translations/financing-ppi-landing-link.configuration.utils.translations';
// Types
import { FinancingPPILandingLinkBuilderType } from '../types/financing-ppi-landing-link.configuration.type';
// Utilities
import { customerHasCurrentlyPPIContracted } from '../../utils/financing-ppi-landing.utils';

const getPPICommercialCardLinkLabel = (
  status: FinancingPPILandingLinkBuilderType['status']
): string => (customerHasCurrentlyPPIContracted(status) ? VIEW_POLICY : VIEW_TERMS_AND_CONDITIONS);

export { getPPICommercialCardLinkLabel };
