// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from 'containers/login/contexts/constants/login.context.constants';
// Enumerations
import { LoginViewsEnumeration } from '../enumerations/login-views.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { LoginContextType } from './types/login.context.type';
import {
  getSavedUserAlias,
  getUsernameEmail,
  userIsRemembered,
} from 'containers/login/contexts/utils/login.context.utils';

const LoginContext = createContext<LoginContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const LoginContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<LoginContextType>> => {
  const [autoLogin, setAutoLogin] = useState<boolean>(userIsRemembered());
  const [email, setEmail] = useState<string>(getUsernameEmail());
  const [userAlias, setUserAlias] = useState<string | null>(getSavedUserAlias());
  const [loginView, setLoginView] = useState<LoginViewsEnumeration>(LoginViewsEnumeration.EMAIL);
  const [rememberMe, setRememberMe] = useState<boolean>(userIsRemembered());

  return (
    <LoginContext.Provider
      value={{
        autoLogin,
        email,
        loginView,
        rememberMe,
        setAutoLogin,
        setEmail,
        setLoginView,
        setRememberMe,
        setUserAlias,
        userAlias,
      }}
    >
      {children as React.ReactNode}
    </LoginContext.Provider>
  );
};

export const LoginContextConsumerHook = (): LoginContextType => useContext(LoginContext);

/* eslint-disable */
export const LoginContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<LoginContextType>> =>
    (
      <LoginContextProvider>
        <Component {...props} />
      </LoginContextProvider>
    );
