// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ListComponent, ExpandableRowComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_DESCRIPTION_01_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_DESCRIPTION_02_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_DESCRIPTION_03_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_EXPANDABLE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_LIST_ITEMS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_LIST_PROPS,
} from './constants/financing-ppi-contracting-step1-coverage-covered.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_03,
  TITLE,
} from './translations/financing-ppi-contracting-step1-coverage-covered.translations';
// Utilities
import { mapAndTranslateListItems } from 'containers/financing/views/ppi/components/contracting/components/step1/components/coverage/components/covered/utils/financing-ppi-contracting-step1-coverage-covered.utils';

const FinancingPPIContractingStep1CoverageCoveredComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <ExpandableRowComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_EXPANDABLE_PROPS}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <LayoutColumnFlexComponentStyled
        {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_LAYOUT_PROPS}
      >
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_DESCRIPTION_01_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_01} />
        </ParagraphComponent>
        <ListComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_LIST_PROPS}
          items={mapAndTranslateListItems({
            ...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_LIST_ITEMS,
            formatMessage,
          })}
        />
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_DESCRIPTION_02_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_02} />
        </ParagraphComponent>
        <ParagraphComponent
          {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_COVERED_DESCRIPTION_03_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION_03} />
        </ParagraphComponent>
      </LayoutColumnFlexComponentStyled>
    </ExpandableRowComponent>
  );
};

export { FinancingPPIContractingStep1CoverageCoveredComponent };
