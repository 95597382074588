// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const FinancingPPIContractingStep3ComponentStyled = styled.section`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${({ theme }) => theme.spaces.spacingXxxxl};
  row-gap: ${({ theme }) => theme.spaces.spacingM};
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;
FinancingPPIContractingStep3ComponentStyled.displayName =
  'FinancingPPIContractingStep3ComponentStyled';

export { FinancingPPIContractingStep3ComponentStyled };
