// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ListComponent, ExpandableRowComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_EXPANDABLE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_ITEMS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_PROPS,
} from './constants/financing-ppi-contracting-step1-coverage-uncovered.constants';
// Translations
import { TITLE } from './translations/financing-ppi-contracting-step1-coverage-uncovered.translations';
// Utilities
import { mapAndTranslateSectionsListItems } from '../../utils/financing-ppi-contracting-step1-coverage.utils';

const FinancingPPIContractingStep1CoverageUncoveredComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <ExpandableRowComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_EXPANDABLE_PROPS}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <ListComponent
        {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_PROPS}
        items={mapAndTranslateSectionsListItems({
          ...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_ITEMS,
          formatMessage,
        })}
      />
    </ExpandableRowComponent>
  );
};

export { FinancingPPIContractingStep1CoverageUncoveredComponent };
