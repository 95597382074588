// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { TitleComponent } from '@openbank/cb-ui-commons';
import { FinancingPpiContractingStep1CoverageCircumstancesComponent } from './components/circumstances/financing-ppi-contracting-step1-coverage-circumstances.component';
import { FinancingPPIContractingStep1CoverageCoveredComponent } from './components/covered/financing-ppi-contracting-step1-coverage-covered.component';
import { FinancingPPIContractingStep1CoverageLimitComponent } from './components/limit/financing-ppi-contracting-step1-coverage-limit.component';
import { FinancingPPIContractingStep1CoverageLimitationsComponent } from './components/limitations/financing-ppi-contracting-step1-coverage-limitations.component';
import { FinancingPPIContractingStep1CoverageTerminateComponent } from './components/terminate/financing-ppi-contracting-step1-coverage-terminate.component';
import { FinancingPPIContractingStep1CoverageUncoveredComponent } from './components/uncovered/financing-ppi-contracting-step1-coverage-uncovered.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_TITLE_PROPS,
} from './constants/financing-ppi-contracting-step1-coverage.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { TITLE } from './translations/financing-ppi-contracting-step1-coverage.translations';

const FinancingPPIContractingStep1CoverageComponent = (): React.ReactElement => (
  <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LAYOUT_PROPS}>
    <TitleComponent {...FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_TITLE_PROPS}>
      <FormattedMessageComponent id={TITLE} />
    </TitleComponent>
    <div>
      <FinancingPPIContractingStep1CoverageCoveredComponent />
      <FinancingPPIContractingStep1CoverageUncoveredComponent />
      <FinancingPPIContractingStep1CoverageLimitationsComponent />
      <FinancingPPIContractingStep1CoverageLimitComponent />
      <FinancingPPIContractingStep1CoverageTerminateComponent />
      <FinancingPpiContractingStep1CoverageCircumstancesComponent />
    </div>
  </LayoutColumnFlexComponentStyled>
);

export { FinancingPPIContractingStep1CoverageComponent };
