// Vendors
import React from 'react';
// Components
import AmortizationModalDesktopComponent from './components/desktop/amortization-modal-desktop.component';
import AmortizationModalMobileComponent from './components/mobile/amortization-modal-mobile.component';
// Hooks
import { FinancingLandingRowDetailsHook } from '../../hooks/financing-landing-row-details-amortization-table.component.hook';
// Types
import { AmortizationModalComponentType } from './types/amortization-modal.component.type';

const AmortizationModalComponent = ({
  installments,
}: AmortizationModalComponentType): React.ReactElement<AmortizationModalComponentType> => {
  const { isMobile } = FinancingLandingRowDetailsHook();

  return isMobile ? (
    <AmortizationModalMobileComponent installments={installments} />
  ) : (
    <AmortizationModalDesktopComponent installments={installments} />
  );
};

export default AmortizationModalComponent;
