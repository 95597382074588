export default {
  FINANCING_LANDING_ROW_DETAILS_AMOUNT_PENDING_PROPS: {
    testing: {
      labelId: 'financing-landing-row-details-amortization-table-label',
      valueId: 'financing-landing-row-details-amortization-table-value',
    },
  },
  FINANCING_LANDING_ROW_DETAILS_AMORTIZATION_TABLE_LINK_PROPS: {
    testId: 'financing-landing-row-details-amortization-table-link',
  },
};
