// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import LoginLinksHandlers from '../handlers/login-links.handlers';
import LoginLinksTrackingHandlers from '../handlers/login-links.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoginLinksBuilderReturnHandlersType } from '../handlers/types/login-links-builder-return.handlers.type';

const LoginLinksHook = (): LoginLinksBuilderReturnHandlersType =>
  LoginLinksHandlers({
    ...AppTrackingHook(LoginLinksTrackingHandlers),
    navigate: useNavigate(),
  });

export default LoginLinksHook;
