// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { useFinancingPPIContextConsumerHook } from '../contexts/financing-ppi.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Handlers
import { FinancingPPIHandlers } from '../handlers/financing-ppi.handlers';
// Types
import { FinancingPPIHookType } from './types/financing-ppi.hook.type';
import { useLocation } from 'react-router-dom';

const useFinancingPPIHook = (): FinancingPPIHookType => {
  const location = useLocation();

  const [fetching, setFetching] = useState<boolean>(true);

  const { fetchPPI, setFetchPPI, setPPIInfo } = useFinancingPPIContextConsumerHook(),
    { setError } = ManagerContextConsumerHook();

  const { handleFetchPPIInformationEvent } = FinancingPPIHandlers({
    setError,
    setFetchPPI,
    setFetching,
    setPPIInfo,
  });

  useEffect(() => {
    handleFetchPPIInformationEvent();
  }, []);

  useEffect(() => {
    fetchPPI && handleFetchPPIInformationEvent();
  }, [location]);

  return {
    fetching,
  };
};

export { useFinancingPPIHook };
