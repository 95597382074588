const FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_PROPS = {
    testId: 'financing-ppi-contracting-step1-coverage-description-link',
  },
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_EXPANDABLE_PROPS = {
    testing: {
      assetId: 'financing-ppi-contracting-step1-coverage-description-link-expandable-asset',
      rowId: 'financing-ppi-contracting-step1-coverage-description-link-expandable-row',
      titleId: 'financing-ppi-contracting-step1-coverage-description-link-expandable-title',
    },
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_DESCRIPTION_LINK_EXPANDABLE_PROPS,
};
