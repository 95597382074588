export const CHANGE_IBAN_STEP1_RESUME_ITEM_01_PROPS = {
  testing: {
    labelId: 'change-iban-step1-resume-item-01-label',
    valueId: 'change-iban-step1-resume-item-01-value',
  },
};

export const CHANGE_IBAN_STEP1_RESUME_ITEM_02_PROPS = {
  testing: {
    labelId: 'change-iban-step1-resume-item-02-label',
    valueId: 'change-iban-step1-resume-item-02-value',
  },
};

export const CHANGE_IBAN_STEP1_RESUME_ITEM_03_PROPS = {
  testing: {
    labelId: 'change-iban-step1-resume-item-03-label',
    valueId: 'change-iban-step1-resume-item-03-value',
  },
};

export const CHANGE_IBAN_STEP1_RESUME_ITEM_04_PROPS = {
  testing: {
    labelId: 'change-iban-step1-resume-item-04-label',
    valueId: 'change-iban-step1-resume-item-04-value',
  },
};

export const CHANGE_IBAN_STEP1_MESSAGE_PROPS = {
  showIcon: false,
  testId: 'change-iban-step1-message',
  type: 'informative',
};

export const CHANGE_IBAN_STEP1_MESSAGE_LINK = {
  testId: 'change-iban-step1-message-link',
};
