// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-cancellation-date.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Translations
import translations from './translations/financing-landing-row-details-cancellation-date.translations';
// Types
import { FinancingLandingRowDetailsCancellationDateComponentType } from './types/financing-landing-row-details-cancellation-date.component.type';

const FinancingLandingRowDetailsCancellationDateComponent = ({
  eppCancellationDate,
  status,
}: FinancingLandingRowDetailsCancellationDateComponentType): React.ReactElement | null => {
  if (!eppCancellationDate || status !== EppElementStatusEnumeration.RETURNED) {
    return null;
  }

  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_CANCELLATION_DATE_PROPS}
      title={<FormattedMessageComponent id={translations.CANCELLATION_DATE} />}
    >
      <ParagraphComponent bold {...constants.FINANCING_LANDING_ROW_DETAILS_CANCELLATION_DATE_PROPS}>
        <FormattedDate
          {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
          value={eppCancellationDate}
        />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsCancellationDateComponent;
