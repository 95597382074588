// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const FinancingPPIContractingStep1HiringOptionsComponentStyled = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;
FinancingPPIContractingStep1HiringOptionsComponentStyled.displayName =
  'FinancingPPIContractingStep1HiringOptionsComponentStyled';

const FinancingPPIContractingStep1HiringOptionsItemComponentStyled = styled.div`
  align-items: center;
  border: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryC};
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    flex: 100%;
    min-width: 100%;
  }
`;
FinancingPPIContractingStep1HiringOptionsItemComponentStyled.displayName =
  'FinancingPPIContractingStep1HiringOptionsItemComponentStyled';

export {
  FinancingPPIContractingStep1HiringOptionsComponentStyled,
  FinancingPPIContractingStep1HiringOptionsItemComponentStyled,
};
