// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import FinancingPaymentMethodFirstStepActionsHook from './hooks/financing-payment-method-first-step-actions.hook';
// Utils
import { IsSelectedOptionInvalid } from './hooks/utils/financing-payment-method-first-step-actions.hook.utils';

const FinancingPaymentMethodFirstStepActionsComponent = (): React.ReactElement => {
  const {
    accountBalancePercentage,
    fee,
    fixedPaymentAmount,
    handleNextButtonClick,
    paymentMethod,
    paymentMethodCode,
    revolvingMethod,
  } = FinancingPaymentMethodFirstStepActionsHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        onClick: handleNextButtonClick,
        disabled: IsSelectedOptionInvalid({
          accountBalancePercentage,
          fee,
          fixedPaymentAmount,
          paymentMethod,
          paymentMethodCode,
          revolvingMethod,
        }),
      }}
    />
  );
};

export default FinancingPaymentMethodFirstStepActionsComponent;
