// Vendors
import React, { useState, useContext, createContext } from 'react';
// Enumerations
import { ConsentPPIStep2IdsEnumeration } from '../enumerations/consent-ppi-step2-ids.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingPPIContractingStep2ContextType } from './types/financing-ppi-contracting-step2.context.type';
import { FinancingPPIContextType } from 'containers/financing/views/ppi/contexts/types/financing-ppi.context.type';

const FinancingPPIContractingStep2Context = createContext<FinancingPPIContractingStep2ContextType>(
  {} as FinancingPPIContractingStep2ContextType
);

const FinancingPPIContractingStep2ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<FinancingPPIContractingStep2ContextType>
> => {
  const [checksDone, setChecksDone] = useState<ConsentPPIStep2IdsEnumeration[]>([]);
  const [errorChecksDone, setErrorChecksDone] = useState<boolean>(false);

  return (
    <FinancingPPIContractingStep2Context.Provider
      value={{
        checksDone,
        errorChecksDone,
        setChecksDone,
        setErrorChecksDone,
      }}
    >
      {children as React.ReactNode}
    </FinancingPPIContractingStep2Context.Provider>
  );
};

const useFinancingPPIContractingStep2ContextConsumerHook =
  (): FinancingPPIContractingStep2ContextType => useContext(FinancingPPIContractingStep2Context);

/* eslint-disable  react/display-name */
const FinancingPPIContractingStep2ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingPPIContractingStep2ContextType>> =>
    (
      <FinancingPPIContractingStep2ContextProvider>
        <Component {...props} />
      </FinancingPPIContractingStep2ContextProvider>
    );

export {
  FinancingPPIContractingStep2ContextConsumerHOC,
  useFinancingPPIContractingStep2ContextConsumerHook,
};
