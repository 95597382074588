export const CHANGE_IBAN_STEP2_MESSAGE = {
  testId: 'change-iban-step2-message-01',
};

export const CHANGE_IBAN_STEP2_MESSAGE_PROPS = {
  showIcon: false,
  testId: 'change-iban-step2-message',
  type: 'informative',
};

export const CHANGE_IBAN_STEP2_NEW_IBAN_ITEM_PROPS = {
  testing: {
    labelId: 'change-iban-step2-new-iban-label',
    valueId: 'change-iban-step2-new-iban-value',
  },
};
