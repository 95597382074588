// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep1CoverageComponent } from './components/coverage/financing-ppi-contracting-step1-coverage.component';
import { FinancingPPIContractingStep1HiringComponent } from './components/hiring/financing-ppi-contracting-step1-hiring.component';
import { FinancingPPIContractingStep1OverviewComponent } from './components/overview/financing-ppi-contracting-step1-overview.component';
import { FinancingPPIContractingStep1WhatComponent } from './components/what/financing-ppi-contracting-step1-what.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_DESCRIPTION_PROPS } from './constants/financing-ppi-contracting-step1.constants';
import { FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS } from '../../constants/financing-ppi-contracting.constants';
// Contexts
import { FinancingPPIContractingStep1ContextConsumerHOC } from './contexts/financing-ppi-contracting-step1.context';
// Hooks
import { useFinancingPPIContractingStep1Hook } from './hooks/financing-ppi-contracting-step1.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from '@/styles/app.styled';
// Translations
import { DESCRIPTION } from './translations/financing-ppi-contracting-step1.translations';

const FinancingPPIContractingStep1BaseComponent = (): React.ReactElement => {
  const { handleValidateAgreementOptionButtonClick: onClick } =
    useFinancingPPIContractingStep1Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <FinancingPPIContractingStep1WhatComponent />
      <FinancingPPIContractingStep1OverviewComponent />
      <FinancingPPIContractingStep1CoverageComponent />
      <FinancingPPIContractingStep1HiringComponent />
      <WizardStepFooterComponent
        {...FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ onClick }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

const FinancingPPIContractingStep1Component = FinancingPPIContractingStep1ContextConsumerHOC(
  FinancingPPIContractingStep1BaseComponent
);

export { FinancingPPIContractingStep1Component };
