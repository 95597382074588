// Types
import {
  FinancingPPILandingLinkBuilderType,
  FinancingPPILandingLinkReturnType,
} from './types/financing-ppi-landing-link.configuration.type';
// Utilities
import { getPPICommercialCardLinkLabel } from './utils/financing-ppi-landing-link.configuration.utils';
import { customerHasCurrentlyPPIContracted } from '../utils/financing-ppi-landing.utils';

const getPPILandingLinkContractConfiguration = ({
  formatMessage,
  onClick,
  status,
}: FinancingPPILandingLinkBuilderType): FinancingPPILandingLinkReturnType =>
  customerHasCurrentlyPPIContracted(status)
    ? {}
    : {
        linkConfiguration: {
          children: formatMessage({ id: getPPICommercialCardLinkLabel(status) }),
          onClick,
        },
      };

export { getPPILandingLinkContractConfiguration };
