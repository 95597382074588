// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FINANCING_PPI_SERVICE_CONFIGURATION } from './constants/financing-ppi.service.constants';
// Types
import { FinancingPPIInfoType } from '../types/financing-ppi-info.type';

const getPPIInfoService = async (): Promise<
  [FinancingPPIInfoType | Record<string, never>, Response]
> => apiUtilsCb.get(FINANCING_PPI_SERVICE_CONFIGURATION);

export { getPPIInfoService };
