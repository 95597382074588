// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_MESSAGE_DESCRIPTION_PROPS,
} from './constants/financing-ppi-contracting-step1-hiring-message.constants';
// Contexts
import { useFinancingPPIContractingStep1ContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/components/step1/contexts/financing-ppi-contracting-step1.context';
// Translations
import { DESCRIPTION } from './translations/financing-ppi-contracting-step1-hiring-message.translations';

const FinancingPPIContractingStep1HiringMessageComponent = (): React.ReactElement | null => {
  const { validated } = useFinancingPPIContractingStep1ContextConsumerHook();

  return validated ? (
    <MessageComponent {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_MESSAGE_PROPS}>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;
};

export { FinancingPPIContractingStep1HiringMessageComponent };
