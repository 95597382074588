// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { RadioButtonComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_NO_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_YES_PROPS,
} from './constants/financing-ppi-contracting-step1-hiring-options.constants';
// Hooks
import { useFinancingPPIContractingStep1HiringOptionsHook } from './hooks/financing-ppi-contracting-step1-hiring-options.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from '@/styles/app.styled';
import {
  FinancingPPIContractingStep1HiringOptionsItemComponentStyled,
  FinancingPPIContractingStep1HiringOptionsComponentStyled,
} from './financing-ppi-contracting-step1-hiring-options.component.styled';
// Translations
import {
  NO,
  YES,
} from './translations/financing-ppi-contracting-step1-hiring-options.translations';
// Utilities
import {
  isNoAgreementContractingOption,
  isYesAgreementContractingOption,
} from './utils/financing-ppi-contracting-step1-hiring-options.utils';

const FinancingPPIContractingStep1OptionsComponent = (): React.ReactElement => {
  const { handleChoosePPIAgreementCheckClick, option } =
    useFinancingPPIContractingStep1HiringOptionsHook();

  return (
    <LayoutColumnFlexComponentStyled>
      <FinancingPPIContractingStep1HiringOptionsComponentStyled>
        <FinancingPPIContractingStep1HiringOptionsItemComponentStyled>
          <RadioButtonComponent
            {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_NO_PROPS}
            onChange={() => handleChoosePPIAgreementCheckClick(false)}
            title={<FormattedMessageComponent id={NO} />}
            value={isNoAgreementContractingOption(option)}
          />
        </FinancingPPIContractingStep1HiringOptionsItemComponentStyled>
        <FinancingPPIContractingStep1HiringOptionsItemComponentStyled>
          <RadioButtonComponent
            {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_OPTION_YES_PROPS}
            onChange={() => handleChoosePPIAgreementCheckClick(true)}
            title={<FormattedMessageComponent id={YES} />}
            value={isYesAgreementContractingOption(option)}
          />
        </FinancingPPIContractingStep1HiringOptionsItemComponentStyled>
      </FinancingPPIContractingStep1HiringOptionsComponentStyled>
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingPPIContractingStep1OptionsComponent };
