// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingPPIContractingStep1ContextType } from './types/financing-ppi-contracting-step1.context.type';

const FinancingPPIContractingStep1Context = createContext<FinancingPPIContractingStep1ContextType>(
  {} as FinancingPPIContractingStep1ContextType
);

const FinancingPPIContractingContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<FinancingPPIContractingStep1ContextType>
> => {
  const [option, setOption] = useState<boolean | null>(null);
  const [validated, setValidated] = useState<boolean>(false);

  return (
    <FinancingPPIContractingStep1Context.Provider
      value={{ option, setOption, validated, setValidated }}
    >
      {children as React.ReactNode}
    </FinancingPPIContractingStep1Context.Provider>
  );
};

const useFinancingPPIContractingStep1ContextConsumerHook =
  (): FinancingPPIContractingStep1ContextType => useContext(FinancingPPIContractingStep1Context);

/* eslint-disable */
const FinancingPPIContractingStep1ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingPPIContractingStep1ContextType>> =>
    (
      <FinancingPPIContractingContextProvider>
        <Component {...props} />
      </FinancingPPIContractingContextProvider>
    );

export {
  FinancingPPIContractingStep1ContextConsumerHOC,
  useFinancingPPIContractingStep1ContextConsumerHook,
};
