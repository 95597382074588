// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const LoginLinksComponentStyled = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: center;
    flex-direction: column;
  }
`;

export const LoginEmailLinksItemComponentStyled = styled.div`
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
