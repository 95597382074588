// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TagComponent } from '@openbank/cb-ui-commons';
// Constants
import { AMORTIZATION_MODAL_DESKTOP_COMPONENT_PROPS } from './constants/amortization-modal-desktop.component.constants';
import { AMORTIZATION_TABLE_STATUS_TAG_TYPES } from '../../constants/amortization-modal.component.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from 'containers/financing/views/landing/components/row/components/details/utils/financing-landing-table-row-details.utils';
// Styles
import {
  AmortizationModalComponentStyled,
  AmortizationModalStyled,
  InstallmentsStyled,
  InstalmentsStyled,
  ParagraphAmountComponentStyled,
  ParagraphCapitalAmountComponentStyled,
  ParagraphCenteredComponentStyled,
  ParagraphComponentStyled,
  ParagraphInterestAmountComponentStyled,
  ParagraphLeftComponentStyled,
  ParagraphTitleCenteredStyled,
} from './amortization-modal-desktop.component.style';
// Translations
import { DESCRIPTIONS } from '../../translations/amortization-modal.translations';
import translations from './translations/amortization-modal-desktop.component.translations';
// Types
import { AmortizationModalComponentType } from '../../types/amortization-modal.component.type';
// Utils
import { isEmphasizedTag, isValidateTag } from '../../utils/amortization-modal.component.utils';
import BoldMessageComponent from 'components/bold-message/bold-message.component';

const AmortizationModalDesktopComponent = ({
  installments,
}: AmortizationModalComponentType): React.ReactElement => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();

  return (
    <AmortizationModalStyled>
      <AmortizationModalComponentStyled>
        {Object.entries(translations).map(([key, value]) =>
          key === AMORTIZATION_MODAL_DESKTOP_COMPONENT_PROPS.key ? (
            <ParagraphTitleCenteredStyled key={key}>
              <BoldMessageComponent>
                <FormattedMessageComponent id={translations.STATUS} uppercase />
              </BoldMessageComponent>
            </ParagraphTitleCenteredStyled>
          ) : (
            <ParagraphComponentStyled key={key}>
              <BoldMessageComponent>
                <FormattedMessageComponent id={value} uppercase />
              </BoldMessageComponent>
            </ParagraphComponentStyled>
          )
        )}
      </AmortizationModalComponentStyled>
      {installments.map(installment => (
        <InstallmentsStyled key={installment.dueDate}>
          <ParagraphLeftComponentStyled>
            <FormattedDate
              {...MiscellaneousDateConstants.FORMAT_DD_MM}
              value={installment.dueDate}
            />
          </ParagraphLeftComponentStyled>
          <InstalmentsStyled>{installment.term}</InstalmentsStyled>
          <ParagraphInterestAmountComponentStyled>
            {formatCurrency(installment.interestAmount)}
          </ParagraphInterestAmountComponentStyled>
          <ParagraphCapitalAmountComponentStyled>
            {formatCurrency(installment.capitalAmount)}
          </ParagraphCapitalAmountComponentStyled>
          <ParagraphCenteredComponentStyled>
            {isValidateTag(installment?.status) ? (
              <TagComponent
                isEmphasizedTag={isEmphasizedTag(
                  AMORTIZATION_TABLE_STATUS_TAG_TYPES[installment.status]
                )}
                testId={AMORTIZATION_MODAL_DESKTOP_COMPONENT_PROPS.testId}
                type={AMORTIZATION_TABLE_STATUS_TAG_TYPES[installment.status]}
              >
                <FormattedMessageComponent uppercase id={DESCRIPTIONS[installment.status]} />
              </TagComponent>
            ) : null}
          </ParagraphCenteredComponentStyled>
          <ParagraphAmountComponentStyled>
            {formatCurrency(installment.dueAmount)}
          </ParagraphAmountComponentStyled>
          <ParagraphComponentStyled>
            <BoldMessageComponent>
              {formatCurrency(installment.outstandingCapital)}
            </BoldMessageComponent>
          </ParagraphComponentStyled>
        </InstallmentsStyled>
      ))}
    </AmortizationModalStyled>
  );
};

export default AmortizationModalDesktopComponent;
