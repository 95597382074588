// Types
import {
  FinancingPpiContractingStep1CoverageCoveredReturnUtilsType,
  FinancingPpiContractingStep1CoverageCoveredUtilsType,
} from './types/financing-ppi-contracting-step1-coverage-covered.utils.type';

const mapAndTranslateListItems = ({
  formatMessage,
  list,
}: FinancingPpiContractingStep1CoverageCoveredUtilsType): FinancingPpiContractingStep1CoverageCoveredReturnUtilsType[] =>
  list.map((id: string) => ({
    label: formatMessage({ id }),
  }));

export { mapAndTranslateListItems };
