const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.cbPPI.landing.';

const DESCRIPTION = `${DOMAIN}description.v2`,
  FIRST_ITEM_TITLE = `${DOMAIN}feature01Title`,
  FIRST_ITEM_DESCRIPTION = `${DOMAIN}feature01Description`,
  SECOND_ITEM_TITLE = `${DOMAIN}feature02Title`,
  SECOND_ITEM_DESCRIPTION = `${DOMAIN}feature02Description`,
  TITLE = `${DOMAIN}title`;

export {
  DESCRIPTION,
  FIRST_ITEM_TITLE,
  FIRST_ITEM_DESCRIPTION,
  SECOND_ITEM_TITLE,
  SECOND_ITEM_DESCRIPTION,
  TITLE,
};
