// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_PPI_PERCENTAGE_PROMILE_BASE } from 'containers/financing/views/ppi/components/landing/configurations/constants/financing-ppi-landing.configuration.constants';
import {
  FINANCING_PPI_CONTRACTING_STEP_3_RESUME_FEE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_RESUME_HOLDER_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_RESUME_PRODUCT_PROPS,
} from './constants/financing-ppi-contracting-step3-resume.constants';
// Hooks
import { useFinancingPPIContractingStep3ResumeHook } from './hooks/financing-ppi-contracting-step3-resume.hook';
// Styles
import { FinancingPPIContractingStep3ComponentStyled } from './financing-ppi-contracting-step3-resume.component.styled';
// Translations
import {
  FEE,
  FEE_VALUE,
  HOLDER,
  PRODUCT_NAME,
} from './translations/financing-ppi-contracting-step3-resume.translations';

const FinancingPPIContractingStep3ResumeComponent = (): React.ReactElement => {
  const { productName, ppiPercentage, userName } = useFinancingPPIContractingStep3ResumeHook();

  return (
    <FinancingPPIContractingStep3ComponentStyled>
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_STEP_3_RESUME_PRODUCT_PROPS}
        label={<FormattedMessageComponent id={PRODUCT_NAME} />}
        value={productName}
      />
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_STEP_3_RESUME_HOLDER_PROPS}
        label={<FormattedMessageComponent id={HOLDER} />}
        value={userName}
      />
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_STEP_3_RESUME_FEE_PROPS}
        label={<FormattedMessageComponent id={FEE} />}
        value={
          <FormattedMessageComponent
            id={FEE_VALUE}
            values={{
              ppiPercentagePromile: (
                <FormattedNumber
                  value={Number(ppiPercentage) * FINANCING_PPI_PERCENTAGE_PROMILE_BASE}
                />
              ),
              ppiPercentage: <FormattedNumber value={Number(ppiPercentage)} />,
            }}
          />
        }
      />
    </FinancingPPIContractingStep3ComponentStyled>
  );
};

export { FinancingPPIContractingStep3ResumeComponent };
