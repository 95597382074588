// Contexts
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
// Types
import { FinancingPPICancellationLandingHookType } from './types/financing-ppi-cancellation-landing.hook.type';

const usePPICancellationLandingHook = (): FinancingPPICancellationLandingHookType => {
  const { PPIInfo } = useFinancingPPIContextConsumerHook(),
    { status } = PPIInfo || {};

  return {
    status,
  };
};

export { usePPICancellationLandingHook };
