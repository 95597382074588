// Enumerations
import { PpiStatusEnumeration } from 'containers/financing/views/ppi/enumerations/ppi-status.enumeration';
// Translations
import {
  DEFINITIVE,
  TRIAL,
} from './translations/financing-ppi-cancellation-landing.utils.translations';

const PPIIsOnTrialPeriod = (status?: PpiStatusEnumeration): boolean =>
  status === PpiStatusEnumeration.COOLING_OFF;

const getPPICancellationDescription = (status?: PpiStatusEnumeration): string =>
  PPIIsOnTrialPeriod(status) ? TRIAL : DEFINITIVE;

export { getPPICancellationDescription, PPIIsOnTrialPeriod };
