// Types
import {
  OrderModalHandlerPropsType,
  OrderModalHandlerTypeReturn,
} from './types/order-modal.handler.type';

const onHandleViewDetailLink = ({
  urlAmazon,
  handleViewDetailClickTracking,
}: OrderModalHandlerPropsType): void => {
  handleViewDetailClickTracking();
  window.open(urlAmazon);
};

const OrderModalHandlers = ({
  urlAmazon,
  handleViewDetailClickTracking,
}: OrderModalHandlerPropsType): OrderModalHandlerTypeReturn => ({
  handleViewDetailLink: () => onHandleViewDetailLink({ urlAmazon, handleViewDetailClickTracking }),
});

export { OrderModalHandlers };
