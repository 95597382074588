const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbFinancing.landing.cbTable.cbDetails.amortizationTable.';

export default {
  SETTLEMENT_DAY: `${DOMAIN}day`,
  INSTALMENT_NUMBER: `${DOMAIN}number`,
  INTERESTS: `${DOMAIN}interests`,
  INSTALMENT_AMOUNT: `${DOMAIN}amount`,
  STATUS: `${DOMAIN}status`,
  TOTAL_PAID: `${DOMAIN}paid`,
  PENDING_AMOUNT: `${DOMAIN}pending`,
};
