// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/login-message.constants';
// Types
import { LoginMessageComponentType } from './types/login-message.component.type';

const LoginMessageComponent = ({
  error,
  message,
}: LoginMessageComponentType): React.ReactElement<LoginMessageComponentType> | null =>
  error ? (
    <MessageComponent {...constants.LOGIN_MESSAGE_PROPS}>
      <ParagraphComponent {...constants.LOGIN_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={message} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;

export default LoginMessageComponent;
