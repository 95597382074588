// Vendors
import React from 'react';
// Components
import AmortizationModalComponent from './components/amortizationModal/amortization-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/financing-landing-row-details-amortization-table.constants';
// Hooks
import { FinancingLandingRowDetailsHook } from './hooks/financing-landing-row-details-amortization-table.component.hook';
// Styles
import {
  AmortizationTableComponentStyled,
  CustomModal,
} from './financing-landing-row-details-amortization-table.component.style';
// Translations
import translations from './translations/financing-landing-row-details-amortization-table.translations';
// Types
import { FinancingLandingRowDetailsAmortizationTableComponentType } from './types/financing-landing-row-details-amortization-table.component.type';

const FinancingLandingRowDetailsAmortizationTableComponent = ({
  installments,
}: FinancingLandingRowDetailsAmortizationTableComponentType): React.ReactElement => {
  const { isModalOpen, handleOpenModal, handleCloseModal } = FinancingLandingRowDetailsHook();
  return (
    <>
      <LinkComponent
        {...constants.FINANCING_LANDING_ROW_DETAILS_AMORTIZATION_TABLE_LINK_PROPS}
        onClick={handleOpenModal}
      >
        <FormattedMessageComponent id={translations.VIEW_AMORTIZATION} />
      </LinkComponent>
      {isModalOpen && (
        <CustomModal
          max-width={100}
          onHeaderClose={handleCloseModal}
          title={<FormattedMessageComponent id={translations.AMORTIZATION_TABLE} />}
        >
          <AmortizationTableComponentStyled>
            <AmortizationModalComponent installments={installments} />
          </AmortizationTableComponentStyled>
        </CustomModal>
      )}
    </>
  );
};

export default FinancingLandingRowDetailsAmortizationTableComponent;
