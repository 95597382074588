// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-total-due.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Hooks
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';
// Translations
import translations from './translations/financing-landing-row-details-total-due.translations';
// Types
import { FinancingLandingRowDetailsTotalDueComponentType } from './types/financing-landing-row-details-total-due.component.type';

const FinancingLandingRowDetailsTotalDueComponent = ({
  totalDueAmount,
}: FinancingLandingRowDetailsTotalDueComponentType): React.ReactElement => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();
  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_TOTAL_DUE_PROPS}
      title={<FormattedMessageComponent id={translations.TOTAL_DUE} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatCurrency(totalDueAmount)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsTotalDueComponent;
