// Vendors
import styled from 'styled-components';
// Utils
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

export const LoginContainerStyled = styled.div`
  height: 100vh;
`;

export const LoginContentContainerStyled = styled.div`
  align-items: stretch;
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  display: flex;
  justify-content: space-between;
  height: calc(100vh - ${convertPxsToRems({ pixels: 64, base: 14 })});
  width: 100%;

  ${mobileMediaQuery} {
    padding-bottom: ${({ theme }) => theme.spaces.spacingXs};
    min-height: calc(100vh - ${convertPxsToRems({ pixels: 64, base: 14 })});
    height: auto;
  }
`;

export const LoginAsideContainerStyled = styled.aside`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundInfoE};
  display: flex;
  height: 100%;
  justify-content: center;
  flex: 1;
  max-width: ${convertPxsToRems({ pixels: 550, base: 14 })};

  ${mobileAndTabletMediaQuery} {
    display: none;
  }
`;

export const LoginFormContainerStyled = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacingXxxxxxl} ${({ theme }) => theme.spaces.spacingL};

  ${tabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingXxxxxxl};
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingXxxl} ${({ theme }) => theme.spaces.spacingS} 0;
    max-width: 100%;
  }
`;

export const LoginFormContentContainerStyled = styled.div`
  max-width: ${convertPxsToRems({ pixels: 625, base: 14 })};
  width: 100%;
`;

export const LoginFormContentChildrenContainerStyled = styled.div`
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  max-width: ${convertPxsToRems({ pixels: 330, base: 14 })};
  width: 100%;

  ${mobileMediaQuery} {
    max-width: 100%;
  }
`;
