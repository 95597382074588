// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, TitleComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep1TermsLinkComponent } from '../terms-link/financing-ppi-contracting-step1-terms-link.component';
// Constants
import { FINANCING_PPI_PERCENTAGE_PROMILE_BASE } from 'containers/financing/views/ppi/components/landing/configurations/constants/financing-ppi-landing.configuration.constants';
import {
  FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_FEATURE_TEST_ID,
  FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_TITLE_PROPS,
} from './constants/financing-ppi-contracting-step1-features-section.constants';
// Hooks
import { useFinancingPPILandingHook } from 'containers/financing/views/ppi/components/landing/hooks/financing-ppi-landing.hook';
// Styles
import {
  FinancingPPIContractingStep1FeaturesSectionComponentStyled,
  FinancingPPIContractingStep1FeaturesSectionListComponentStyled,
  FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled,
} from './financing-ppi-contracting-step1-features-section.component.styled';
// Types
import { FinancingPPIContractingStep1FeaturesSectionType } from './types/financing-ppi-contracting-step1-features-section.type';
import { FormattedNumber } from 'react-intl';

const FinancingPPIContractingStep1FeaturesSectionComponent = ({
  features,
  title,
}: FinancingPPIContractingStep1FeaturesSectionType): React.ReactElement => {
  const { ppiPercentage } = useFinancingPPILandingHook();
  return (
    <FinancingPPIContractingStep1FeaturesSectionComponentStyled>
      <TitleComponent {...FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_TITLE_PROPS}>
        <FormattedMessageComponent id={title} />
      </TitleComponent>
      <FinancingPPIContractingStep1FeaturesSectionListComponentStyled>
        {features.map((id: string, key: number) => (
          <FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled key={key}>
            <ParagraphComponent
              key={id}
              testId={FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_FEATURE_TEST_ID + key}
            >
              <FormattedMessageComponent
                id={id}
                values={{
                  link: (label: string | React.ReactNode) => (
                    <FinancingPPIContractingStep1TermsLinkComponent {...{ label }} />
                  ),
                  ppiPercentagePromile: (
                    <FormattedNumber
                      value={Number(ppiPercentage) * FINANCING_PPI_PERCENTAGE_PROMILE_BASE}
                    />
                  ),
                  ppiPercentage: <FormattedNumber value={Number(ppiPercentage)} />,
                }}
              />
            </ParagraphComponent>
          </FinancingPPIContractingStep1FeaturesSectionListItemComponentStyled>
        ))}
      </FinancingPPIContractingStep1FeaturesSectionListComponentStyled>
    </FinancingPPIContractingStep1FeaturesSectionComponentStyled>
  );
};

export { FinancingPPIContractingStep1FeaturesSectionComponent };
