// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/financing-landing-row-details-amount-repaid-earlier.constants';
import { PARAGRAPH_COMPONENT_PROPS } from '../../constants/financing-landing-table-row-details.component.constants';
// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Utils
import { FinancingLandingTableRowDetailsUtils } from '../../utils/financing-landing-table-row-details.utils';
// Translations
import translations from './translations/financing-landing-row-details-amount-repaid-earlier.translations';
// Types
import { FinancingLandingRowDetailsAmountRepaidEarlierComponentType } from './types/financing-landing-row-details-amount-repaid-earlier.component.type';

const FinancingLandingRowDetailsAmountRepaidEarlierComponent = ({
  pendingAmount,
  status,
}: FinancingLandingRowDetailsAmountRepaidEarlierComponentType): React.ReactElement | null => {
  const { formatCurrency } = FinancingLandingTableRowDetailsUtils();

  if (status !== EppElementStatusEnumeration.CANCELLED) {
    return null;
  }

  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_AMOUNT_REPAID_EARLIER_PROPS}
      title={<FormattedMessageComponent id={translations.AMOUNT_REPAID_EARLIER} />}
    >
      <ParagraphComponent {...PARAGRAPH_COMPONENT_PROPS}>
        <FormattedMessageComponent id={formatCurrency(pendingAmount)} />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsAmountRepaidEarlierComponent;
