// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_TITLE_PROPS = {
    testId: 'financing-ppi-contracting-step1-features-section-title',
    tag: titleConstants.TAGS.H5,
  },
  FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_FEATURE_TEST_ID =
    'financing-ppi-contracting-step1-features-section-feature-';

export {
  FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_TITLE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_FEATURES_SECTION_FEATURE_TEST_ID,
};
